import { setUpSAS } from "./auth/SAS";
import { IMAGE_SCHEMA } from "../consts/Template";
import uniqid from "uniqid";
import moment from "moment";
import { JWT } from "../consts/AuthFields";
import { isUserAdmin } from "./user";
import Configs from "../Configurations";
import _ from 'lodash';
import { MEDIA_SCHEMA } from "../consts/MediaLibrary";

let UPLOAD_RETRY = 0;
let MAX_UPLOAD_RETRY = 2;
let FETCH_RETRY = 0;
let MAX_FETCH_RETRY = 2;

const IMAGE_RETENTION_DAYS = 60;

// upload a provided file
export const uploadFile = async (
  sasId,
  campaignId,
  user,
  connectionType = "data",
  file,
  useUniqueId
) => {
  UPLOAD_RETRY = UPLOAD_RETRY + 1;
  const token = await setUpSAS(sasId, connectionType);
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      const binaryStr = reader.result;
      try {
        const fileName = useUniqueId
          ? makeUniqueFileName(file.name)
          : makeFileName(campaignId, connectionType, file);
        const blockBlobClient =
          token.CONTAINER_CLIENT.getBlockBlobClient(fileName);
        let metadata = {
          name: file.name,
        };
        if (user) {
          metadata[IMAGE_SCHEMA.OWNER] = user[JWT.UPN];
        }
        await blockBlobClient.uploadBrowserData(binaryStr, {
          metadata: metadata,
        });
        UPLOAD_RETRY = 0;
        resolve({
          isError: false,
          message: `${token.SAS_URL}${token.SAS_CONTAINER}/${fileName}`, //return the url of the file
          blob: fileName,
          fileName,
          sas_container: token.SAS_CONTAINER,
        });
      } catch (err) {
        // check if error type is authentication failure
        // if so, try fetching a new SAS since it may have expired
        resolve({
          isError: true,
          message: err,
          retry: UPLOAD_RETRY <= MAX_UPLOAD_RETRY ? true : false,
        });
      }
    };
    reader.readAsArrayBuffer(file);
  }).catch((err) => {
    return err;
  });
};

export const softDeleteImage = async (teamId, image) => {
  let name = image?.[IMAGE_SCHEMA.BLOB_NAME];
  if (name) {
    try {
      const token = await setUpSAS(teamId, "image");
      const deletedOn = String(new Date());
      const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(name);
      await blockBlobClient.setMetadata({
        name: image?.[IMAGE_SCHEMA.NAME],
        [IMAGE_SCHEMA.DELETED_ON]: deletedOn,
      });
      return;
    } catch (err) {
      return err;
    }
  } else {
    return true;
  }
};

export const renameImage = async (teamId, image) => {
  let name = image?.[IMAGE_SCHEMA.BLOB_NAME];
  if (name) {
    try {
      const token = await setUpSAS(teamId, "image");
      const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(name);
      const metadata = {
        [IMAGE_SCHEMA.NAME]: image?.[IMAGE_SCHEMA.NAME],
      };
      if (image?.[IMAGE_SCHEMA.OWNER]) {
        metadata[IMAGE_SCHEMA.OWNER] = image[IMAGE_SCHEMA.OWNER];
      }
      await blockBlobClient.setMetadata(metadata);
      return;
    } catch (err) {
      return err;
    }
  } else {
    return true;
  }
};

// returns the original name of an uploaded campaign csv
export const getUploadedCSVFileName = async (teamId, campaignId) => {
  if (teamId && campaignId) {
    try {
      const token = await setUpSAS(teamId, "data");
      const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(
        makeFileName(campaignId, "data", null)
      );
      let exists = await blockBlobClient.exists();
      if (exists) {
        var blobProperties = await blockBlobClient.getProperties();
        return { name: blobProperties?.metadata?.name };
      }
    } catch (err) {
      return null;
    }
  }
  return undefined;
};

// deletes the file name
export const removeUploadedCSVFileName = async (teamId, campaignId) => {
  if (teamId && campaignId) {
    try {
      const token = await setUpSAS(teamId, "data");
      const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(
        makeFileName(campaignId, "data", null)
      );
      let exists = await blockBlobClient.exists();
      if (exists) {
        await blockBlobClient.delete();
        return true;
      }
      return false;
    } catch (err) {
      return null;
    }
  }
  return undefined;
};

// if csv, make the name the same as the campaign id
export const makeFileName = (campaignId, connectionType = "data", file) => {
  return connectionType === "data" ? `${campaignId}.csv` : file.name;
};

export const makeUniqueFileName = (fileName) => {
  let name =
    fileName && typeof fileName === "string"
      ? `${uniqid()}-${fileName?.replace(/\s/g, "-")}`
      : null;
  return name;
};

// gets image assets for a particular team for the template editor
export const getTeamAssets = async (teamId, isTeamOwner, user) => {
  FETCH_RETRY = FETCH_RETRY + 1;
  let assets = [];
  let toDelete = [];
  if (teamId) {
    try {
      const tokens = await setUpSAS(teamId, "image", true);
      for (let token of tokens) {
        const assetItem = { assets: [] };
        if (token.THEME_NAME) assetItem["themeName"] = token.THEME_NAME;
        for await (const blob of token.CONTAINER_CLIENT.listBlobsFlat({
          includeMetadata: true,
        })) {
          const src = `${Configs.REACT_APP_IMAGE_STORAGE_URL}/${token.SAS_CONTAINER}/${blob.name}`
          let deletedOn = blob.metadata?.[IMAGE_SCHEMA.DELETED_ON];
          if (deletedOn === undefined) {
            assetItem.assets.push({
              [IMAGE_SCHEMA.SRC]: src,
              [IMAGE_SCHEMA.NAME]:
                blob.metadata?.[IMAGE_SCHEMA.NAME] || blob[IMAGE_SCHEMA.NAME],
              [IMAGE_SCHEMA.CREATED_ON]:
                blob.properties?.[IMAGE_SCHEMA.CREATED_ON],
              [IMAGE_SCHEMA.BLOB_NAME]: blob.name,
              [IMAGE_SCHEMA.OWNER]: blob.metadata?.[IMAGE_SCHEMA.OWNER],
              [IMAGE_SCHEMA.CAN_EDIT]:
                isTeamOwner ||
                userHasEditPermissions(blob, user) ||
                isUserAdmin(user),
              [IMAGE_SCHEMA.FOLDER_ID]: blob.metadata?.[IMAGE_SCHEMA.FOLDER_ID] || null
            });
          } else {
            const now = moment.now();
            deletedOn = moment(deletedOn);
            let timeSinceDelete = moment
              .duration(deletedOn.diff(now))
              ?.asDays();
            if (Math.abs(timeSinceDelete) >= IMAGE_RETENTION_DAYS) {
              toDelete.push(blob); // hard delete the blob
            }
          }
        }
        FETCH_RETRY = 0;
        toDelete?.forEach((blob) => {
          try {
            const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(
              blob.name
            );
            blockBlobClient.delete();
          } catch {}
        });
        assets.push(assetItem);
      }
      return {
        error: false,
        assets: assets,
      };
    } catch (err) {
      return {
        error: true,
        assets: [],
        retry: FETCH_RETRY <= MAX_FETCH_RETRY ? true : false,
      };
    }
  }
  return {
    error: true,
    assets: [],
  };
};

export const userHasEditPermissions = (image, user) => {
  return (
    image?.metadata?.[IMAGE_SCHEMA.OWNER]?.toLowerCase() ===
    user?.[JWT.UPN]?.toLowerCase()
  );
};

export const downloadCSV = async (teamId, campaignId) => {
  if (teamId && campaignId) {
    try {
      const token = await setUpSAS(teamId, "data");
      const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(
        makeFileName(campaignId, "data", null)
      );
      let exists = await blockBlobClient.exists();
      if (exists) {
        let file = await getUploadedCSVFileName(teamId, campaignId);
        var blobProperties = await blockBlobClient.getProperties();
        const downloadBlockBlobResponse = await blockBlobClient.download();
        const downloaded = await blobToString(
          await downloadBlockBlobResponse.blobBody
        );
        var data = new Blob([downloaded], { type: "text/csv" });
        if (data) {
          var csvURL = window.URL.createObjectURL(data);
          let tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute(
            "download",
            `${file ? file.name : "unnamedfile.csv"}`
          );
          tempLink.click();
          return { name: blobProperties?.metadata?.name };
        } else {
          return null;
        }
      }
    } catch (err) {
      return null;
    }
  }
};

const blobToString = async (blob) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
};


export const downloadMedia = async (media) => {
  const fileSrc = media[MEDIA_SCHEMA.SRC];
  if(!fileSrc) return false;
  try{
    const response = await fetch(fileSrc);
    const blob = await response.blob();
    const href = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = href;
    const fileName = media[MEDIA_SCHEMA.BLOB_NAME];
    link.setAttribute(
      'download',
      fileName,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return true;
  } catch (error) {
    return false;
  }
}

export const uploadWithProgress = async (
  sasId,
  user,
  file,
  setProgress,
  metadataOptions = {},
  connectionType = "data",
) => {
  UPLOAD_RETRY = UPLOAD_RETRY + 1;
  const token = await setUpSAS(sasId, connectionType);
  const reader = new FileReader();
  return new Promise((resolve, reject) => {

    const fileSize = file.size;

    reader.onload = async () => {
      const binaryStr = reader.result;
      try {
        const sanitizedfileName = removeUnicodeCharactersFromFilename(file.name)
        const fileName = makeUniqueFileName(sanitizedfileName);
        const blockBlobClient =
          token.CONTAINER_CLIENT.getBlockBlobClient(fileName);
        let metadata = {
          name: sanitizedfileName,
        };
        if(metadataOptions[MEDIA_SCHEMA.FOLDER_ID]) {
          metadata[MEDIA_SCHEMA.FOLDER_ID] = metadataOptions[MEDIA_SCHEMA.FOLDER_ID];
        }
        if (user) {
          metadata[MEDIA_SCHEMA.OWNER] = user[JWT.UPN];
        }

        await blockBlobClient.uploadBrowserData(
          binaryStr,
          {
            metadata: metadata,
            onProgress: (e) => {
              setProgress((previousProgresses) => {
                const progresses = _.cloneDeep(previousProgresses);
                let progress = {
                  fileName: file.name,
                  value: (e?.loadedBytes / fileSize) * 100,
                }
                progresses[file.name] = progress;
                return progresses;
              });
            }
          }
        )

        UPLOAD_RETRY = 0;
        resolve({
          isError: false,
          message: `${token.SAS_URL}${token.SAS_CONTAINER}/${fileName}`, //return the url of the file
          blob: fileName,
          fileName,
          sanitizedfileName,
          sas_container: token.SAS_CONTAINER,
        });
      } catch (err) {
        // check if error type is authentication failure
        // if so, try fetching a new SAS since it may have expired
        resolve({
          isError: true,
          message: err,
          retry: UPLOAD_RETRY <= MAX_UPLOAD_RETRY ? true : false,
        });
      }
    };
    reader.readAsArrayBuffer(file);
  }).catch((err) => {
    return {
      message: err.message,
      status: "error"
    };
  });
};

export const removeUnicodeCharactersFromFilename = (filename) => {
  const _filename = filename.replace(/[\u{0080}-\u{FFFF}]/gu, "");
  return (!_filename) ? uniqid()  : _filename;
}
