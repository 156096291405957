import { FEATURE } from "../../../consts/Common";
import { GALLERY_SCHEMA } from "../../../consts/Template";
import Configs from "../../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const CorporateUpdate = {
  [GALLERY_SCHEMA.ID]: "comms_corporate_update",
  [GALLERY_SCHEMA.NAME]: "Corporate Update",
  [GALLERY_SCHEMA.CATEGORY]: FEATURE.COMMS_TEMPLATES,
  template_content: `<mjml><mj-body id="ivpy" background-color="#f0f0f0"><mj-section padding-top="12px" padding-bottom="12px" padding-left="20px" padding-right="20px" id="ifple" background-color="#0071dc" border-radius="24px 24px 0 0"><mj-column><mj-image src="${blob_url}/templateeditortestteam-image/Walmart_Logos_Lockup_horiz_wht_rgb_31971798-47a3-4222-a67b-2362cf0252bf.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="i50cl" width="180px" align="center"></mj-image></mj-column></mj-section><mj-section padding-top="20px" padding-bottom="0px" padding-left="20px" padding-right="20px" id="i78oa" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ikyfl"><p><span style="color:#041f41"><strong>To:</strong> Lorem Ipsum</span></p>

<p><span style="color:#041f41"><strong>From:</strong> Lorem Ipsum</span></p>

<p><span style="color:#041f41"><strong>Date:</strong> September 1, 2024</span></p>

<p><span style="color:#041f41"><strong>Re:</strong> Subject line goes here</span></p>
</mj-text><mj-divider border-width="2px" padding-top="20px" padding-bottom="20px" padding-left="0px" padding-right="0px" border-color="#f0f0f0" id="ir4c6"></mj-divider><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="is4g7"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Dignissim enim sit amet venenatis urna cursus. Risus pretium quam vulputate dignissim suspendisse in. Velit dignissim sodales ut eu. Gravida quis blandit turpis cursus in hac habitasse. Odio facilisis mauris sit amet. Magna fermentum iaculis eu non. Varius vel pharetra vel turpis nunc eget. Vitae tempus quam pellentesque nec. Mi sit amet mauris commodo quis.</p>

<p>&nbsp;</p>

<p>Faucibus vitae aliquet nec ullamcorper sit. Mauris nunc congue nisi vitae suscipit tellus mauris a. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Eu tincidunt tortor aliquam nulla facilisi. Semper risus in hendrerit gravida rutrum quisque non tellus. Sit amet commodo nulla facilisi nullam. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing. Eros in cursus turpis massa tincidunt. Nunc scelerisque viverra mauris in. Adipiscing at in tellus integer feugiat. Tellus molestie nunc non blandit massa enim nec.</p>
</mj-text><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align:center"><span style="color:#041f41"><span style="font-size:36pt">...</span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="irtul" background-color="#0071dc" border-radius="0 0 24px 24px"><mj-column><mj-text padding-top="15px" padding-bottom="15px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ir1qf"><p style="text-align:center"><span style="font-size:9pt"><span style="color:#ffffff">2024 Walmart Inc. - Confidential - Internal use only</span></span></p>

<p style="text-align:center"><span style="font-size:9pt"><span style="color:#ffffff">Sent with Spark Notifications</span></span></p>
</mj-text></mj-column></mj-section></mj-body><mj-head><mj-style>
    p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }
  </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <title>
          
        </title>
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!--<![endif]-->
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style type="text/css">
          #outlook a { padding:0; }
          body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
          table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
          img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
          p { display:block;margin:13px 0; }
        </style>
        <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
        <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
        
        
    <style type="text/css">
      @media only screen and (min-width:480px) {
        .mj-column-per-100 { width:100% !important; max-width: 100%; }
      }
    </style>
    <style media="screen and (min-width:480px)">
      .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
    </style>
    
  
        <style type="text/css">
        
        

    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile { width: 100% !important; }
      td.mj-full-width-mobile { width: auto !important; }
    }
  
        </style>
        <style type="text/css">p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }</style>
        
      </head>
      <body style="word-spacing:normal;background-color:#f0f0f0;">
        
        
      <div
         style="background-color:#f0f0f0;"
      >
        
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#041f41" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#0071dc;background-color:#0071dc;margin:0px auto;border-radius:24px 24px 0 0;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0071dc;background-color:#0071dc;width:100%;border-radius:24px 24px 0 0;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:12px;padding-left:20px;padding-right:20px;padding-top:12px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:180px;">
              
      <img
         height="auto" src="${blob_url}/templateeditortestteam-image/Walmart_Logos_Lockup_horiz_wht_rgb_31971798-47a3-4222-a67b-2362cf0252bf.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="180"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:20px;padding-right:20px;padding-top:20px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="color:#041f41"><strong>To:</strong> Lorem Ipsum</span></p>

<p><span style="color:#041f41"><strong>From:</strong> Lorem Ipsum</span></p>

<p><span style="color:#041f41"><strong>Date:</strong> September 1, 2024</span></p>

<p><span style="color:#041f41"><strong>Re:</strong> Subject line goes here</span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:20px;padding-right:0px;padding-bottom:20px;padding-left:0px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:560px;" role="presentation" width="560px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Dignissim enim sit amet venenatis urna cursus. Risus pretium quam vulputate dignissim suspendisse in. Velit dignissim sodales ut eu. Gravida quis blandit turpis cursus in hac habitasse. Odio facilisis mauris sit amet. Magna fermentum iaculis eu non. Varius vel pharetra vel turpis nunc eget. Vitae tempus quam pellentesque nec. Mi sit amet mauris commodo quis.</p>

<p>&nbsp;</p>

<p>Faucibus vitae aliquet nec ullamcorper sit. Mauris nunc congue nisi vitae suscipit tellus mauris a. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Eu tincidunt tortor aliquam nulla facilisi. Semper risus in hendrerit gravida rutrum quisque non tellus. Sit amet commodo nulla facilisi nullam. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing. Eros in cursus turpis massa tincidunt. Nunc scelerisque viverra mauris in. Adipiscing at in tellus integer feugiat. Tellus molestie nunc non blandit massa enim nec.</p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><span style="color:#041f41"><span style="font-size:36pt">...</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#041f41" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#0071dc;background-color:#0071dc;margin:0px auto;border-radius:0 0 24px 24px;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0071dc;background-color:#0071dc;width:100%;border-radius:0 0 24px 24px;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:15px;padding-right:5px;padding-bottom:15px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><span style="font-size:9pt"><span style="color:#ffffff">2024 Walmart Inc. - Confidential - Internal use only</span></span></p>

<p style="text-align:center"><span style="font-size:9pt"><span style="color:#ffffff">Sent with Spark Notifications</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><![endif]-->
    
    
      </div>
    
      </body>
    </html>`,
};
export default CorporateUpdate;
