import {
  CONFIGURATIONS,
  EXTERNAL_USER_OPTIONS_CONFIGS,
  NOTIFICATION,
  PRIORITIES,
  RECIPIENTS,
  TRACKING_CONFIGS,
} from "./DBFields";
// import { FEATURE } from "./Common";

// MUI
import { HelpOutline } from "@material-ui/icons";

export const ATTACHMENT_FILE_TYPES =
  ".png, .jpg, .ics, .csv, .xlsx, .pptx, .docx, .zip, .pdf";

export const ATTACHMENT_LIMIT = 10;
export const ATTACHMENT_SIZE_LIMIT_MB = 10;

export const CODES = {
  CAMPAIGN_NAME_TAKEN: "CAMPAIGN_OBJ_ERR001",
};

export const CSV_HEADER_ERROR = {
  message:
    "Please remove special characters from CSV Column Headers and re-upload your file",
};

export const API_VERSION = {
  API_VERSION_V1: "v1",
  API_VERSION_V2: "v2"
}

export const NOTIFICATION_CHANNELS = {
  mail: {
    value: "mail",
    label: "Email",
    caption: "Recipients will receive the notification in their inbox.",
  },
  teams: {
    value: "teams",
    label: "Teams",
    caption:
      "Recipients will receive the notification as a direct message in Teams.",
  },
};

export const CAMPAIGN_TYPES = {
  general: {
    value: "general",
    label: "Standard",
    caption:
      "Recommended for sending emails to large audiences by Distribution List or CSV",
    channels: [NOTIFICATION_CHANNELS.mail.value],
    dataSourceTypes: {
      CSV: {
        value: "csv",
      },
      MANUAL: {
        value: "manual",
      },
    },
    showRecommendedBanner: true,
    hoverTooltipText: "Standard option is 50x faster than Advanced option."
  },
  personalized: {
    value: "personalized",
    label: "Advanced",
    caption:
      "Recommended for sending emails to internal and external audiences by CSV with personalised content.",
    dataSourceTypes: {
      CSV: {
        value: "csv",
      },
      MS_SQL: { value: "ms-sql" },
      REST_API: { value: "rest-api" },
    },
    defaultType: "CSV",
    channels: [
      NOTIFICATION_CHANNELS.mail.value,
      NOTIFICATION_CHANNELS.teams.value,
    ],
    hoverTooltipText: "Advanced option supports Personalized content."
  },
};

export const CAMPAIGN_TYPES_FEATURES = {
  [NOTIFICATION_CHANNELS.mail.value]: [
    {
      label: "Customizable templates",
      types: [CAMPAIGN_TYPES.general.value, CAMPAIGN_TYPES.personalized.value],
    },
    {
      label: "Personalized email for each recipient",
      types: [CAMPAIGN_TYPES.personalized.value],
    },
    {
      label: "Distribution lists",
      types: [CAMPAIGN_TYPES.general.value],
    },
    {
      label: "CSV Upload",
      types: [CAMPAIGN_TYPES.general.value, CAMPAIGN_TYPES.personalized.value],
    },
    {
      label: "Supports summarized tracking",
      types: [CAMPAIGN_TYPES.general.value, CAMPAIGN_TYPES.personalized.value],
    },
    {
      label: "Supports email analytics",
      types: [CAMPAIGN_TYPES.general.value, CAMPAIGN_TYPES.personalized.value],
    },
  ],
};

export const UI_DATA = {
  CHANNELS: "channels",
  MANUAL_RECIPIENTS: {
    [RECIPIENTS.TO]: "manual_data_to",
    [RECIPIENTS.CC]: "manual_data_cc",
    [RECIPIENTS.BCC]: "manual_data_bcc",
  },
  SNIPPET_TEXT: "sn_email_snippet",
};

export const CAMPAIGN_STATUSES = {
  SENT: {
    value: 0,
    label: "Sent",
  },
  SCHEDULED: {
    value: 1,
    label: "Scheduled",
  },
  DRAFT: {
    value: 2,
    label: "Draft",
  },
};

export const SUBJECT_MAX_CHAR = 150;
export const SNIPPET_MAX_CHAR = 150;
export const DISPLAY_NAME_MAX_CHAR = 64;
export const FROM_MAX_CHAR = 189;

export const CAMPAIGN_FIELDS = {
  [NOTIFICATION.RECIPIENTS]: [
    {
      label: "To",
      name: RECIPIENTS.TO,
      placeholder: "Who are you sending this campaign to?",
      required: true,
      channels: [
        NOTIFICATION_CHANNELS.mail.value,
        NOTIFICATION_CHANNELS.teams.value,
      ],
      includeGroups: true,
    },
    {
      label: "Cc",
      name: RECIPIENTS.CC,
      required: false,
      channels: [NOTIFICATION_CHANNELS.mail.value],
      includeGroups: true,
    },
    {
      label: "Bcc",
      name: RECIPIENTS.BCC,
      required: false,
      channels: [NOTIFICATION_CHANNELS.mail.value],
      includeGroups: true,
    },
  ],
  [NOTIFICATION.CONFIGURATIONS]: [
    {
      name: CONFIGURATIONS.SUBJECT,
      label: "Subject",
      required: true,
      type: "text",
      placeholder: "What is the subject of this email?",
      max: SUBJECT_MAX_CHAR,
      errorText: `Subject should not exceed ${SUBJECT_MAX_CHAR} characters`,
      multiline: true,
      width: {
        xs: 12,
        sm: 6,
        md: 8,
      },
      channels: [NOTIFICATION_CHANNELS.mail.value],
    },
    {
      name: CONFIGURATIONS.PRIORITY,
      label: "Priority",
      required: true,
      type: "select",
      options: [
        { value: PRIORITIES.low.value, label: PRIORITIES.low.label },
        { value: PRIORITIES.medium.value, label: PRIORITIES.medium.label },
        { value: PRIORITIES.high.value, label: PRIORITIES.high.label },
      ],
      defaultValue: PRIORITIES.medium.value,
      width: {
        xs: 12,
        sm: 4,
        md: 2,
      },
      channels: [NOTIFICATION_CHANNELS.mail.value],
    },
    {
      name: UI_DATA.SNIPPET_TEXT,
      label: "Snippet Text",
      type: "text",
      max: SNIPPET_MAX_CHAR,
      errorText: `Snippet text should not exceed ${SNIPPET_MAX_CHAR} characters`,
      multiline: true,
      tooltip: {
        icon: <HelpOutline fontSize="small" color="disabled" />,
        title:
          "The snippet text is the very first line of text that your email recipient will see, following the email subject line.",
      },
      channels: [NOTIFICATION_CHANNELS.mail.value],
    },
    {
      name: CONFIGURATIONS.FROM,
      label: "From",
      required: true,
      max: FROM_MAX_CHAR,
      errorText: `From field cannot exceed ${FROM_MAX_CHAR} characters`,
      channels: [NOTIFICATION_CHANNELS.mail.value],
    },
    {
      name: CONFIGURATIONS.FROM_DISPLAY_NAME,
      label: "Display Name",
      type: "text",
      max: DISPLAY_NAME_MAX_CHAR,
      errorText: `Display name should not exceed ${DISPLAY_NAME_MAX_CHAR} characters`,
      multiline: false,
      tooltip: {
        icon: <HelpOutline fontSize="small" color="disabled" />,
        title: "Customize the name your recipients see in their mailbox.",
      },
      channels: [NOTIFICATION_CHANNELS.mail.value],
    },
    {
      name: CONFIGURATIONS.ATTACHMENTS,
      label: "Attachments",
      type: "file",
      channels: [NOTIFICATION_CHANNELS.mail.value],
      accept: ATTACHMENT_FILE_TYPES,
      maxFiles: ATTACHMENT_LIMIT,
      sizeLimit: ATTACHMENT_SIZE_LIMIT_MB * 1000000,
    },
  ],
  TRACKING_TITLE: {
    [NOTIFICATION_CHANNELS.mail.value]:
      "How do you want to track the performance of your message?",
    [NOTIFICATION_CHANNELS.teams.value]: null,
  },
  TRACKING: [
    {
      name: TRACKING_CONFIGS.VIEW,
      label: "Track opens",
      helperText:
        "Discover who opens your campaign and how many total opens the message received.",
      defaultValue: false,
      channels: [NOTIFICATION_CHANNELS.mail.value],
    },
    {
      name: TRACKING_CONFIGS.CLICK,
      label: "Track clicks",
      helperText:
        "Discover which links were clicked, how many times they were clicked and who did the clicking.",
      defaultValue: false,
      channels: [
        NOTIFICATION_CHANNELS.mail.value
      ],
    },
    {
      name: TRACKING_CONFIGS.ADVANCED_METRICS,
      label: "Advanced tracking",
      helperText:
        "Discover the Operating System, Device and Browser behind your message opens.",
      defaultValue: false,
      channels: [NOTIFICATION_CHANNELS.mail.value],
      enableIfTracked: true,
      enableRef: TRACKING_CONFIGS.VIEW,
      // featureToggle: FEATURE.ADVANCED_TRACKING,
      // the above line caused a deadlock scenario with imports
      featureToggle: "advanced_tracking",
    },
    {
      name: TRACKING_CONFIGS.TRACK_LOCATION,
      label: "Track Locations",
      helperText:
        "Discover the Location behind the clicks done on your message.",
      defaultValue: false,
      channels: [NOTIFICATION_CHANNELS.mail.value],
      enableIfTracked: true,
      enableRef: TRACKING_CONFIGS.CLICK,
      featureToggle: "location_tracking",
    },
  ],
  EXTERNAL_USER_OPTIONS_TITLE: "Is the message targeted to external users?*",
  EXTERNAL_USER_OPTIONS: [
    {
      name: EXTERNAL_USER_OPTIONS_CONFIGS.INCLUDE_UNSUBSCRIBE_LINK,
      label: "Is the message targeted to external users?",
      helperText: "Select an option to confirm whether this message is targeted to external users",
      defaultValue: false,
    }
  ]
};

export const CAMPAIGN_STATS_API_FIELDS = {
  SENT: "sent_count",
  VIEWS: "view_count",
  CLICKS: "click_count",
  UNIQUE_VIEWS: "unique_view_count",
  UNIQUE_CLICKS: "unique_click_count",
  ERRORS: "error_count",
  QUEUED: "queued_count",
  UNIQUE_CLICKED_USER: "unique_clicked_user_count",
  VIEW_ADVANCED_METRICS: "view_advanced_metrics",
  BROWSER_METRICS: "browser_metrics",
  DEVICE_METRICS: "device_metrics",
  OS_METRICS: "os_metrics",
  DOC_COUNT: "doc_count",
};

export const STATS_LIST = {
  BROWSER: ["Chrome", "Safari", "Firefox", "Others"],
  DEVICE: ["Desktop", "Mobile", "Others"],
  OS: ["Windows", "Mac OS", "iOS", "Android", "Others"],
};

export const CAMPAIGN_STATS_FIELDS = {
  RECIPIENTS: "recipients",
  VIEWS: "viewCount",
  CLICKS: "clickCount",
  UNIQUE_VIEWS: "uniqueViews",
  UNIQUE_CLICKS: "uniqueClicks",
  OPEN_RATE: "openRate",
  ERRORS: "errorCount",
  CLICK_RATE: "clickRate",
  UNIQUE_CLICKED_USER: "uniqueClickedUser",
  QUEUED: "queuedCount",
  UNSUBSCRIBED_COUNT: "unsubscribedCount",
  NOT_SENT_COUNT: "notSentCount",
};

export const REPORT_INTERVALS = {
  HOURS: "hour",
  DAYS: "day",
  WEEKS: "week",
  MONTHS: "month",
  QUARTERS: "quarter",
  YEARS: "year",
};

export const SENDER_CATEGORIES = {
  ME: "Me",
  TEAM: "My Team",
  MAILBOXES: "Shared Mailboxes",
  OTHER: "Other",
};

export const MANUAL_DATA_SOURCE = {
  TITLE: "Enter recipient details manually",
  SUB_TITLE: "You need to type the every details manually",
};
export const CAMPAIGN_FAILURE_DETAILS = {
  ERROR_CODE: "errorCode",
  ERROR_TEXT: "errorText",
  ERROR_LEVEL: "errorLevel",
  RECIPIENT: "recipient",
  TIMESTAMP: "timestamp",
};

export const CAMPAIGN_SENT_DETAILS = {
  SENT_DISPLAY_NAME: "sentDisplayName",
  SENT_EMAIL: "sentEmail",
  SENT_MEMBER_COUNT: "sentMemberCount"
};

export const CAMPAIGN_UNSUBSCRIBE_DETAILS = {
  RECIPIENT_EMAIL: "recipientEmail"
}

export const CAMPAIGN_FAILURE_COLUMNS = [
  {
    field: CAMPAIGN_FAILURE_DETAILS.RECIPIENT,
    label: "Recipient",
    type: "recipient",
  },
  {
    field: CAMPAIGN_FAILURE_DETAILS.TIMESTAMP,
    label: "Timestamp",
    type: "timestamp",
  },
  {
    field: CAMPAIGN_FAILURE_DETAILS.ERROR_TEXT,
    label: "Reason",
  },
];

export const CAMPAIGN_SENT_COLUMNS = [
  {
    field: CAMPAIGN_SENT_DETAILS.SENT_EMAIL,
    label: "Recipient",
    type: "recipient",
  },
  {
    field: CAMPAIGN_SENT_DETAILS.SENT_DISPLAY_NAME,
    label: "Display Name"
  },
  {
    field: CAMPAIGN_SENT_DETAILS.SENT_MEMBER_COUNT,
    label: "Member Count",
  },
];

export const CAMPAIGN_FAILURE_WITH_DUPLICATES_COLUMN = [
  {
    field: CAMPAIGN_FAILURE_DETAILS.RECIPIENT,
    label: "Recipient",
    type: "recipient",
  },
  {
    field: "count",
    label: "Duplicates",
    type: "duplicates",
  },
  {
    field: CAMPAIGN_FAILURE_DETAILS.TIMESTAMP,
    label: "Timestamp",
    type: "timestamp",
  },
  {
    field: CAMPAIGN_FAILURE_DETAILS.ERROR_TEXT,
    label: "Reason",
  },
];

export const CAMPAIGN_UNSUBSCRIBE_COLUMNS = [
  {
    field: CAMPAIGN_UNSUBSCRIBE_DETAILS.RECIPIENT_EMAIL,
    label: "Recipient",
    type: "recipient",
  },
]

export const CAMPAIGN_NOT_SENT_COLUMNS = [
  {
    field: CAMPAIGN_UNSUBSCRIBE_DETAILS.RECIPIENT_EMAIL,
    label: "Recipient",
    type: "recipient",
  },
  {
    field: "reason",
    label: "Reason",
    type: "reason",
  }
]

export const CAMPAIGN_SENT_RECIPIENTS_COLUMNS = [
  {
    field: CAMPAIGN_UNSUBSCRIBE_DETAILS.RECIPIENT_EMAIL,
    label: "Recipient",
    type: "recipient",
  },
]

export const CAMPAIGN_UNIQUE_OPENS_COLUMNS = [
  {
    field: CAMPAIGN_UNSUBSCRIBE_DETAILS.RECIPIENT_EMAIL,
    label: "Recipient",
    type: "recipient",
  },
  {
    field: "viewCount",
    label: "Opens",
    type: "opens",
  }
]

export const CAMPAIGN_UNIQUE_CLICKS_COLUMNS = [
  {
    field: CAMPAIGN_UNSUBSCRIBE_DETAILS.RECIPIENT_EMAIL,
    label: "Recipient",
    type: "recipient",
  },
  {
    field: "clickCount",
    label: "Clicks",
    type: "clicks",
  }
]

export const CAMPAIGN_FAILURE_PAGES = [15, 30, 50, 100];
export const CAMPAIGN_UNSUBSCRIBED_PAGES = [15, 30, 50, 100];

export const MOVE_FOLDER_VERBAGE = {
  MOVE_FOLDER_PRIMARY : "Select a folder to move the message",
  MOVE_FOLDER_HELPER: "Dropdown will only contain the folders which you have access for."
}

export const VERBAGE = {
  OPEN_RATE:
    `A unique open rate is not applicable for Standard messages. Click the "View" button to see Total Opens for this message.`,
  OPEN_RATE_INDIVIDUAL:
    "A unique open rate is not applicable for Standard messages."
};

export const CAMPAIGN_REPORT_NAME = {
  UNIQUE_CLICKS: "Clicks",
  UNIQUE_VIEWS: "Views",
  SENT_RECIPIENTS: "Sent-Recipients",
  UNSUBSCRIBED: "Unsubscribed",
  NOT_SENT: "Not-Sent",
}