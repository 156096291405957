import Configs from '../Configurations';

export const URLS = {
  CAMPAIGNS_API: "CAMPAIGNS_API",
  TEMPLATES_API: "TEMPLATES_API",
  TEAMS_API: "TEAMS_API",
  USERS_API: "USERS_API",
  SEARCH_AD_API: "SEARCH_AD_API",
  CCM_API: "CCM_API",
  FOLDERS_API: "FOLDERS",
  CAMPAIGNS_API_V2: "CAMPAIGNS_API_V2",
  ENTERPRISE_ADMIN_API: "ENTERPRISE_ADMIN_API",
};

export function getAPIEndPoint(api) {
  switch (api) {
    case URLS.CAMPAIGNS_API:
      return `${Configs.REACT_APP_API_ENDPOINT}campaigns`;
    case URLS.CAMPAIGNS_API_V2:
      return `${Configs.REACT_APP_API_ENDPOINT}v2/campaigns`;
    case URLS.TEMPLATES_API:
      return `${Configs.REACT_APP_API_ENDPOINT}templates`;
    case URLS.TEAMS_API:
      return `${Configs.REACT_APP_API_ENDPOINT}teams`;
    case URLS.USERS_API:
      return `${Configs.REACT_APP_API_ENDPOINT}users`;
    case URLS.SEARCH_AD_API:
      return `${Configs.REACT_APP_API_ENDPOINT}searchAD`;
    case URLS.CCM_API:
      return `${Configs.REACT_APP_API_ENDPOINT}ccm`;
    case URLS.FOLDERS_API:
      return `${Configs.REACT_APP_API_ENDPOINT}folders`;
    case URLS.ENTERPRISE_ADMIN_API:
      return `${Configs.REACT_APP_API_ENDPOINT}enterprise-stats`;
    default:
      return Configs.REACT_APP_API_ENDPOINT;
  }
}
