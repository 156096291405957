export const sidePanelOptions = [
    {
        name: "Enterprise Campaign Dashboard",
        path: "/admindashboard/campaignDashboard",
        panelName: "campaignDashboard",
    },
    {
        name: "Enterprise Teams Dashboard",
        path: "/admindashboard/teamsDashboard",
        panelName: "teamsDashboard",
    },
]

export const rowsPerPageTeamsTableECD = 5;

export const ECD_METRICS = {
    "TOTAL_MESSAGES_DELIVERED" : "total_messages_delivered",
    "TOTAL_EMAILS_DELIVERED" : "total_emails_delivered",
    "TOTAL_MS_TEAMS_NOTIFICATIONS_DELIVERED" : "total_ms_teams_notifications_delivered",
    "TOTAL_STANDARD_MAILS_DELIVERED" : "total_standard_mails_delivered",
    "TOTAL_ADVANCED_MAILS_DELIVERED" : "total_advanced_mails_delivered",
    "TOTAL_CAMPAIGNS" : "total_campaigns",
    "TOTAL_EMAIL_CAMPAIGNS" : "total_email_campaigns",
    "TOTAL_MS_TEAMS_CAMPAIGNS" : "total_ms_teams_campaigns",
    "TOTAL_STANDARD_MAIL_CAMPAIGNS" : "total_standard_mail_campaigns",
    "TOTAL_ADVANCED_MAIL_CAMPAIGNS" : "total_advanced_mail_campaigns",
    "AVERAGE_SUCCESS_RATE" : "average_success_rate",
    "TOTAL_MAIL_OPENS": "total_mail_opens",
    "TOTAL_MAIL_CLICKS": "total_mail_clicks",
    "UNIQUE_MAIL_OPENS": "unique_mail_opens",
    "UNIQUE_MAIL_CLICKS": "unique_mail_clicks",
    "MAIL_CLICK_RATE": "mail_click_rate",
    "MAIL_OPEN_RATE": "mail_open_rate",
}

export const ECD_TOP_TEAMS_PROPERTIES = {
    "TEAM_ID": "team_id",
    "TEAM_NAME": "team_name",
    "TOTAL_CAMPAIGN_EXECUTIONS" : "total_campaign_executions",
    "TOTAL_SENT_COUNT" : "total_sent_count",
    "TOTAL_MAIL_MESSAGES" : "total_mail_messages",
    "TOTAL_TEAMS_MESSAGES" : "total_teams_messages",
    "TOTAL_UNIQUE_RECIPIENTS" : "total_unique_recipients",
    "LAST_SENT_DATE" : "last_sent_date",
}