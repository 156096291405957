import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useMemo,
    useState
} from 'react'
import {
    ECD_METRICS,
    ECD_TOP_TEAMS_PROPERTIES,
    rowsPerPageTeamsTableECD,
} from '../consts/AdminDashboard';
import {
    getDBDataForECD,
    getESDataForECD,
    getRealtimeDataForECD,
    getTeamStats,
    getThroughPutDataForECD
} from '../APIs/AdminDashboardAPI';
import { ENTERPRISE_CAMPAIGN_DASHBOARD_REPORT_DATE_OPTIONS } from '../consts/DataViz';
import { useAppContext } from './Core';
import { SNACK_TYPES } from '../consts/Common';
import { isEmpty, debounce } from "lodash";
import { validateEmail } from '../utils/campaign';
import { getAggregateFieldFromESResponse } from '../utils/adminDashboard';

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const AdminDashboardState = Context.Consumer;
export const useAdminDashboardContext = () => useContext(Context);

function AdminDashboard({ children }) {

    const {
        setSnackMessage,
        setSnackType,
        userTeams,
    } = useAppContext();

    //Enterprice Campaign Dashboard States
    const [isECDMetricsLoaded, setIsECDMetricsLoaded] = useState(false);
    const [showThroughput, setShowThroughput] = useState(false);
    const [loadingThroughput, setLoadingThroughput] = useState(false);
    const [throughputStats, setThroughputStats] = useState({});
    const [loadingMongoResultsForECD, setLoadingMongoResultsForECD] = useState(false);
    const [loadingESResultsForECD, setLoadingESResultsForECD] = useState(false);
    const [loadingStatsRefreshForECD, setLoadingStatsRefreshForECD] = useState(false);
    const [realTimeCampaignMetrics, setRealTimeCampaignMetrics] = useState({});
    const [realTimeCampaignMetricsCount, setRealTimeCampaignMetricsCount] = useState({});
    const [loadingRealtimeCampaignData, setLoadingRealtimeCampaignData] = useState(false);
    const [loadingDateRangeChange, setLoadingDateRangechange] = useState(false);
    const [dateRangeECD, setDateRangeECD] = useState({});
    const [metricDataForECD, setMetricDataForECD] = useState({
        total_messages_delivered: null,
        total_emails_delivered: null,
        total_ms_teams_notifications_delivered: null,
        total_standard_mails_delivered: null,
        total_advanced_mails_delivered: null,
        total_campaigns: null,
        total_email_campaigns: null,
        total_ms_teams_campaigns: null,
        total_standard_mail_campaigns: null,
        total_advanced_mail_campaigns: null,
        average_success_rate: null,
        total_mail_opens: null,
        total_mail_clicks: null,
        unique_mail_opens: null,
        unique_mail_clicks: null,
        mail_click_rate: null,
        mail_open_rate: null,
    });
    //ECD Selected date dropdown option state
    const [selectedDateOptionForECDOption, setSelectedDateOptionForECDOption] = useState(
        ENTERPRISE_CAMPAIGN_DASHBOARD_REPORT_DATE_OPTIONS[3]?.title
    );

    //Teams table states
    const [teamsTableData, setTeamsTableData] = useState({});
    const [teamsTableCurrentPageData, setTeamsTableCurrentPageData] = useState([]);
    const [teamsTableTotalDataCount, setTeamsTableTotalDataCount] = useState(0);
    const [teamsTableCurrentPage, setTeamsTableCurrentPage] = useState(0);
    const [teamsTablePageLoading, setTeamsTablePageLoading] = useState(false);

    //teams dashboard
    const searchCategoriesForETD = [
        {key: "team-search", value:"teams", label:"Search by team"},
        {key:"user-search", value:"user", label: "Search by user email"}
    ]
    const [totalTeamCountForETD, setTotalTeamCountForETD] = useState("NA")
    const [totalMemberCountForETD, setTotalMemberCountForETD] = useState("NA")
    const [teamDetailsTableDataForETD, setTeamDetailsTableDataForETD] = useState([]);
    const [cachedTeamDetailsTableDataForETD, setCachedTeamDetailsTableDataForETD] = useState({});
    const [currentTablePageForETD, setCurrentTablePageForETD] = useState(0);
    const [rowsPerPageETD, setRowsPerPageETD] = useState(5);
    const [totalTableDataETD, setTotalTableDataETD] = useState(1)
    const [searchPhraseForETD, setSearchPhraseForETD] = useState("");
    const [selectedSearchCategoryForETD, setSelectedSearchCategoryForETD] = useState(searchCategoriesForETD[0])
    const [loadingDataETD, setLoadingDataETD] = useState(false)
    const [isSearchEmailValid, setIsSearchEmailValid] = useState(true)
    const [isETDDataLoaded, setIsETDDataLoaded] = useState(false)

    useEffect(() => {
        loadTeamsTablePage(teamsTableCurrentPage, dateRangeECD);
    }, [teamsTableCurrentPage]);

    const fetchThroughput = async () => {
        setLoadingThroughput(true);
        try{
            const result = await getThroughPutDataForECD({
                startDate: dateRangeECD?.startDate,
                endDate: dateRangeECD?.endDate,
            });
            setShowThroughput(true);
            setThroughputStats({
                averageThoughput: isNaN(result?.throughput) ? result?.throughput : `${Math.ceil(result?.throughput)} per minute`,
            });
        } catch (error) {
            setSnackType(SNACK_TYPES.ERROR);
            if(error?.response?.status === 400) {
                setSnackMessage(`${error?.response?.data?.message}`);
            } else {
                setSnackMessage("Error occurred while fetching the throughput, please try again!");
            }
        } finally {
            setLoadingThroughput(false);
        }
    }

    const fetchECDStats = async (dateRange) => {
        const resultSet = await Promise.allSettled([
            fetchMongoResultsForECD(dateRange),
            fetchESResultsForECD(dateRange),
            fetchRealtimeCampaignMetrics()
        ]);
        let errorResponsesPresent = false;
        const unprocessedData = resultSet.map(result => {
            if(result.status === 'fulfilled')
                return result.value;
            else {
                errorResponsesPresent = true;
                return {};
            }
        });
        if(errorResponsesPresent) {
            setSnackType(SNACK_TYPES.ERROR);
            setSnackMessage("Error occurred while fetching the metric, please try again!");
        }
        const processedData = processDataForECDMetrics(unprocessedData);
        setMetricDataForECD(processedData["metricDataForECD"]);
        setTeamsTableData({
            0: processedData["teamsTableData"]
        });
        setTeamsTableTotalDataCount(processedData["totalTeams"]);
        setTeamsTableCurrentPageData(processedData["teamsTableData"]);
        const realtimeData = processedData["realtimeData"] || {};
        setRealTimeCampaignMetricsCount({
            "index": "Campaigns",
            "In progress": realtimeData["campaignsInProgress"]?.length || 0,
            "Failed": realtimeData["campaignsFailed"]?.length || 0,
            "Scheduled": realtimeData["campaignsScheduled"]?.length || 0,
        })
        setRealTimeCampaignMetrics(realtimeData);
    }

    const fetchMongoResultsForECD = async (dateRange) => {
        setLoadingMongoResultsForECD(true);
        try{
            return await getDBDataForECD({
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
            });
        } catch (error) {
            throw error;
        } finally {
            setLoadingMongoResultsForECD(false);
        }
    }

    const fetchESResultsForECD = async (dateRange) => {
        setLoadingESResultsForECD(true);
        try{
            return await getESDataForECD({
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
                from: 0,
                size: rowsPerPageTeamsTableECD,
            });
        } catch (error) {
            throw error;
        } finally {
            setLoadingESResultsForECD(false);
        }
    }

    const fetchRealtimeCampaignMetrics = async () => {
        setLoadingRealtimeCampaignData(true);
        try{
            return await getRealtimeDataForECD();
        } catch (error) {
            throw error;
        } finally {
            setLoadingRealtimeCampaignData(false);
        }
    }

    const refreshStatsForECD = async () => {
        setLoadingStatsRefreshForECD(true);
        resetECDStats();
        await fetchECDStats(dateRangeECD);
        setLoadingStatsRefreshForECD(false);
    }

    const resetECDStats = () => {
        setTeamsTableData({});
        setTeamsTableCurrentPageData([]);
        setTeamsTableCurrentPage(0);
        setThroughputStats({});
    }

    const processDataForECDMetrics = (data) => {
        const _metricDataForECD = {}
        let dbMetrics = data[0].length <= 0 ? {} : data[0]["statsFromDb"];
        dbMetrics = (!dbMetrics || dbMetrics?.length <= 0) ? {} : dbMetrics[0];
        const esMetrics = data[1];

        const totalSentDeliveriesForStandard = assignFallbackValue(dbMetrics["totalSentDeliveriesForStandard"], 0);
        const totalSentDeliveriesForAdvanced = assignFallbackValue(dbMetrics["totalSentDeliveriesForAdvanced"], 0);

        const totalMailsMessageCount = totalSentDeliveriesForStandard + totalSentDeliveriesForAdvanced;
        const totalTeamsMessageCount = assignFallbackValue(esMetrics["totalTeamsMessageCount"], 0);

        _metricDataForECD[ECD_METRICS.TOTAL_MESSAGES_DELIVERED] = totalMailsMessageCount + totalTeamsMessageCount;
        _metricDataForECD[ECD_METRICS.TOTAL_EMAILS_DELIVERED] = totalMailsMessageCount;
        _metricDataForECD[ECD_METRICS.TOTAL_MS_TEAMS_NOTIFICATIONS_DELIVERED] = totalTeamsMessageCount;
        _metricDataForECD[ECD_METRICS.TOTAL_STANDARD_MAILS_DELIVERED] = totalSentDeliveriesForStandard;
        _metricDataForECD[ECD_METRICS.TOTAL_ADVANCED_MAILS_DELIVERED] = totalSentDeliveriesForAdvanced;
        _metricDataForECD[ECD_METRICS.TOTAL_CAMPAIGNS] = assignFallbackValue(dbMetrics["totalCampaigns"]?.[0]?.["count"], null);
        _metricDataForECD[ECD_METRICS.TOTAL_EMAIL_CAMPAIGNS] = assignFallbackValue(dbMetrics["totalEmailCampaigns"]?.[0]?.["count"], null);
        _metricDataForECD[ECD_METRICS.TOTAL_MS_TEAMS_CAMPAIGNS] = assignFallbackValue(dbMetrics["totalTeamsCamapaigns"]?.[0]?.["count"], null);
        _metricDataForECD[ECD_METRICS.TOTAL_STANDARD_MAIL_CAMPAIGNS] = assignFallbackValue(dbMetrics["totalStandardCampaigns"]?.[0]?.["count"], null);
        _metricDataForECD[ECD_METRICS.TOTAL_ADVANCED_MAIL_CAMPAIGNS] = assignFallbackValue(dbMetrics["totalAdvancedCampaigns"]?.[0]?.["count"], null);

        let averageSuccessRate = esMetrics?.averageSuccessRate?.aggregations?.average_success_rate?.value;
        _metricDataForECD[ECD_METRICS.AVERAGE_SUCCESS_RATE] = isNaN(averageSuccessRate) ? "NA" : `${Math.floor(averageSuccessRate * 100) / 100}%`;
        _metricDataForECD[ECD_METRICS.TOTAL_MAIL_OPENS] = getAggregateFieldFromESResponse(esMetrics["viewStats"], "view_count");
        _metricDataForECD[ECD_METRICS.TOTAL_MAIL_CLICKS] = getAggregateFieldFromESResponse(esMetrics["clickStats"], "click_count");
        _metricDataForECD[ECD_METRICS.UNIQUE_MAIL_OPENS] = getAggregateFieldFromESResponse(esMetrics["viewStats"], "unique_view_count");
        _metricDataForECD[ECD_METRICS.UNIQUE_MAIL_CLICKS] = getAggregateFieldFromESResponse(esMetrics["clickStats"], "unique_click_count");

        const click_rate = getAggregateFieldFromESResponse(esMetrics["clickStats"], "click_rate");
        _metricDataForECD[ECD_METRICS.MAIL_CLICK_RATE] = isNaN(click_rate) ? "NA" : `${Math.floor(click_rate * 100) / 100}%`;
        const view_rate = getAggregateFieldFromESResponse(esMetrics["viewStats"], "view_rate");
        _metricDataForECD[ECD_METRICS.MAIL_OPEN_RATE] = isNaN(view_rate) ? "NA" : `${Math.floor(view_rate * 100) / 100}%`;

        const _teamsTableData = esMetrics["topTeamsStats"]?.["buckets"]?.map((data) => {
            return {
                [ECD_TOP_TEAMS_PROPERTIES.TEAM_ID] : data?.key,
                [ECD_TOP_TEAMS_PROPERTIES.TEAM_NAME] : getTeamNamefromId(userTeams, data?.key)?.trim(),
                [ECD_TOP_TEAMS_PROPERTIES.TOTAL_MAIL_MESSAGES] : data?.total_mail_messages?.buckets?.[0]?.doc_count,
                [ECD_TOP_TEAMS_PROPERTIES.TOTAL_CAMPAIGN_EXECUTIONS] : data?.total_campaign_executions?.value,
                [ECD_TOP_TEAMS_PROPERTIES.TOTAL_TEAMS_MESSAGES] : data?.total_teams_messages?.buckets[0]?.doc_count,
                [ECD_TOP_TEAMS_PROPERTIES.TOTAL_UNIQUE_RECIPIENTS] : data?.total_unique_recipients?.value,
                [ECD_TOP_TEAMS_PROPERTIES.TOTAL_SENT_COUNT] : data?.total_sent_count?.buckets?.[0]?.sent_count?.value_as_string,
                [ECD_TOP_TEAMS_PROPERTIES.LAST_SENT_DATE] : data?.total_sent_count?.buckets?.[0]?.last_sent_date?.value_as_string,
            }
        });

        const totalTeams = esMetrics["totalTeams"]?.["value"];

        const realtimeData = assignFallbackValue(data[2]?.["realTimeStats"], {});

        return {
            metricDataForECD: _metricDataForECD,
            teamsTableData: assignFallbackValue(_teamsTableData, []),
            totalTeams: assignFallbackValue(totalTeams, 0),
            realtimeData,
        };
    }

    const assignFallbackValue = (actualValue, fallbackValue) => {
        return actualValue || fallbackValue;
    }

    const loadTeamsTablePage = async (currentPage, dateRange) => {
        let _teamsTableData = teamsTableData;
        let loadedPage = []
        if(teamsTableData?.[teamsTableCurrentPage]) {
            setTeamsTableCurrentPageData(teamsTableData[teamsTableCurrentPage]);
            return;
        }
        if(teamsTableCurrentPage !== 0) {
            loadedPage = await fetchTeamTabledata(currentPage, dateRange);
        }
        setTeamsTableData({
            ..._teamsTableData,
            [currentPage]: loadedPage
        });
        setTeamsTableCurrentPageData([...loadedPage]);
    }

    const fetchTeamTabledata = async (page, dateRange) => {
        setTeamsTablePageLoading(true);
        try {
            const result = await getESDataForECD({
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
                size: rowsPerPageTeamsTableECD,
                from: page * rowsPerPageTeamsTableECD,
            });
            const _teamsTableData = result["topTeamsStats"]?.["buckets"]?.map((data) => {
                return {
                    [ECD_TOP_TEAMS_PROPERTIES.TEAM_NAME]: getTeamNamefromId(userTeams, data?.key)?.trim(),
                    [ECD_TOP_TEAMS_PROPERTIES.TEAM_ID]: data?.key,
                    [ECD_TOP_TEAMS_PROPERTIES.TOTAL_MAIL_MESSAGES]: data?.total_mail_messages?.buckets?.[0]?.doc_count,
                    [ECD_TOP_TEAMS_PROPERTIES.TOTAL_CAMPAIGN_EXECUTIONS]: data?.total_campaign_executions?.value,
                    [ECD_TOP_TEAMS_PROPERTIES.TOTAL_TEAMS_MESSAGES]: data?.total_teams_messages?.buckets[0]?.doc_count,
                    [ECD_TOP_TEAMS_PROPERTIES.TOTAL_UNIQUE_RECIPIENTS]: data?.total_unique_recipients?.value,
                    [ECD_TOP_TEAMS_PROPERTIES.TOTAL_SENT_COUNT]: data?.total_sent_count?.buckets?.[0]?.sent_count?.value_as_string,
                    [ECD_TOP_TEAMS_PROPERTIES.LAST_SENT_DATE] : data?.total_sent_count?.buckets?.[0]?.last_sent_date?.value_as_string,
                }
            });
            return _teamsTableData || [];
        } catch (error) {
            throw error;
        } finally {
            setTeamsTablePageLoading(false);
        }
    }

    const getTeamNamefromId = (userTeams, teamId) => {
        if(!userTeams) return teamId;
        for(const team of userTeams) {
            if(team?.["id"] === teamId) {
                return team?.["name"] || teamId;
            }
        }
        return teamId;
    }

    const updateDateRange = async (dateRange) => {
        resetECDStats();
        setLoadingDateRangechange(true);
        const { startDate, endDate } = dateRange;
        setDateRangeECD({
            startDate,
            endDate,
        });
        await fetchECDStats(dateRange);
        setLoadingDateRangechange(false);
    };

    const totalCountsETDLoadedref = useRef(false)

    const getSearchResultsForETD = async (searchCategory, rowsPerPage, searchPhrase = "") => {
      try {
        if (!isEmpty(cachedTeamDetailsTableDataForETD)) {
          setCachedTeamDetailsTableDataForETD({});
        }
        setCurrentTablePageForETD(0)
        await getTeamCountStatsForETD({
          size: rowsPerPage,
          from: 0,
          searchBy: searchCategory,
          searchText: searchPhrase,
        });
      } catch (error) {
        throw error;
      }
    };

    const debouncedSearchHandler = useMemo(
      () => debounce(getSearchResultsForETD, 1000),
      []
    );

    useEffect(() => {
      return () => {
        debouncedSearchHandler.cancel();
      };
    }, []);

    useEffect(() => {
        setIsSearchEmailValid(true)
        setSearchPhraseForETD("")
    }, [selectedSearchCategoryForETD])

    const handleSearchPhraseChangeForETD = async (searchPhrase = "") => {
      setSearchPhraseForETD(searchPhrase);
      if (searchPhrase?.length < 3) return;

      if(selectedSearchCategoryForETD?.value === "user"){
        if(!validateEmail(searchPhraseForETD)){
            setIsSearchEmailValid(false)
            return
        }
        else {
            setIsSearchEmailValid(true)
        }
      }
      await debouncedSearchHandler(selectedSearchCategoryForETD?.value, rowsPerPageETD, searchPhrase);
    };

    const getTeamCountStatsForETD = async (params) => {
        setLoadingDataETD(true)
        try{
            const response = await getTeamStats(params);
            const teamStats = response?.statsFromDb

            if(teamStats?.totalTeamsCount){
                setTotalTeamCountForETD(teamStats?.totalTeamsCount)
                if(!totalCountsETDLoadedref.current) totalCountsETDLoadedref.current=true
            }
            if(teamStats?.totalUsersCount){
                setTotalMemberCountForETD(teamStats?.totalUsersCount)
            }
            if(teamStats?.teamsWithUserData){
                let finalTeamStats = teamStats?.teamsWithUserData?.teams?.map((team) =>{
                    const emailList = team?.teams_users?.map((user) => user?.email?.toLowerCase() );
                    return {...team, teams_users: emailList};
                })
                if(params?.searchBy==="user" && params?.searchText!=="") {
                    finalTeamStats = finalTeamStats?.map((team) => {
                        let membersList = new Set([params?.searchText?.toLowerCase(), ...team?.teams_users])
                        membersList = [...membersList];
                        return {...team, teams_users: membersList}
                    })
                }
                setTeamDetailsTableDataForETD(finalTeamStats);
                setTotalTableDataETD(teamStats?.teamsWithUserData?.totalDocuments)
            }
        }
        catch(error){
            throw(error);
        }
        finally{
            setLoadingDataETD(false)
        }
    }

    const initialState = {
        fetchThroughput,
        refreshStatsForECD,
        fetchECDStats,
        updateDateRange,
        setTeamsTableCurrentPage,
        showThroughput,
        setShowThroughput,
        loadingThroughput,
        throughputStats,
        teamsTableData,
        teamsTableCurrentPageData,
        teamsTableTotalDataCount,
        teamsTableCurrentPage,
        teamsTablePageLoading,
        loadingMongoResultsForECD,
        loadingESResultsForECD,
        loadingStatsRefreshForECD,
        loadingRealtimeCampaignData,
        realTimeCampaignMetrics,
        realTimeCampaignMetricsCount,
        loadingDateRangeChange,
        dateRangeECD,
        setDateRangeECD,
        metricDataForECD,
        isECDMetricsLoaded,
        setIsECDMetricsLoaded,
        selectedDateOptionForECDOption,
        setSelectedDateOptionForECDOption,
        teamDetailsTableDataForETD,
        setTeamDetailsTableDataForETD,
        searchPhraseForETD,
        handleSearchPhraseChangeForETD,
        searchCategoriesForETD,
        selectedSearchCategoryForETD,
        setSelectedSearchCategoryForETD,
        getTeamCountStatsForETD,
        totalTeamCountForETD,
        totalMemberCountForETD,
        setTotalTeamCountForETD,
        setTotalMemberCountForETD,
        cachedTeamDetailsTableDataForETD,
        setCachedTeamDetailsTableDataForETD,
        currentTablePageForETD,
        setCurrentTablePageForETD,
        rowsPerPageETD,
        setRowsPerPageETD,
        totalTableDataETD,
        setTotalTableDataETD,
        loadingDataETD,
        isSearchEmailValid,
        totalCountsETDLoadedref,
        setIsETDDataLoaded,
        isETDDataLoaded,
        setSearchPhraseForETD
    }
    return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default AdminDashboard;
