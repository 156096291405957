import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useAppContext } from "./Core";
import { sortMedia, isMediaOwner } from '../utils/mediaUtil';
import { useFoldersContext } from '../contexts/FoldersList';
import { FOLDER, REQUEST_PARAMS, TEAM } from '../consts/DBFields';
import { MEDIA_SCHEMA, MEDIA_TYPES, SORTING_OPTIONS } from '../consts/MediaLibrary';
import { FOLDER_LEVEL_PERMISSIONS } from '../consts/Folders';
import { downloadMedia, getTeamAssets, renameImage as renameMedia, softDeleteImage, uploadWithProgress} from '../utils/blobStorage';
import { setToken } from '../utils/auth/SAS';
import { isUserAdmin } from '../utils/user';
import Configs from "../Configurations";
import { EVENTS, EVENT_TYPES, SNACK_TYPES } from '../consts/Common';
import { JWT } from '../consts/AuthFields';
import _ from 'lodash';
import { trackEvent } from '../utils/appInsight';

export const Context = createContext();
export const MediaLibraryState = Context.Consumer;
export const useMediaLibraryContext = () => useContext(Context);

function MediaLibrary ({ children }) {

    const {
        selectedTeam,
        signedInUserIsOwner,
        user,
        setSnackMessage,
        setSnackType,
    } = useAppContext();

    const {
        teamFolders,
        getFolderById,
    } = useFoldersContext();

    const [folderId, setFolderId] = useState(null);
    const [defaultImages, setDefaultImages] = useState();
    const [mediaLoadedForTeamId, setMediaLoadedForTeamId] = useState(null);
    const [allTeamMedia, setAllTeamMedia] = useState([]);
    const [folderMedia, setFolderMedia] = useState([]);

    //Error states
    const [toggleMediaError, setToggleMediaError] = useState();

    //States for sort / filtering
    const [currentSort, setCurrentSort] = useState({
        sortBy: SORTING_OPTIONS.NEWEST.params[REQUEST_PARAMS.SORT_BY],
        sortOrder: SORTING_OPTIONS.NEWEST.params[REQUEST_PARAMS.SORT_ORDER],
    });
    const [searchFilter, setSearchFilter] = useState("");
    const [filteredMedia, setFilteredMedia] = useState({
        [MEDIA_TYPES.ALL.value]: [],
        [MEDIA_TYPES.IMAGES.value]: [],
        [MEDIA_TYPES.GIFS.value]: [],
        [MEDIA_TYPES.DOCUMENTS.value]: [],
    });
    const [filteredMediaForSelectedTab, setFilteredMediaForSelectedTab] = useState([]);
    const [selectedMediaTab, setSelectedMediaTab] = useState(MEDIA_TYPES.ALL.value);

    const [loadingMedia, setLoadingMedia] = useState(false);

    //States for media preview
    const [selectedMediaForPreviewIndex, setSelectedMediaForPreviewIndex] = useState(-1);

    //States for multiple media selection
    const [multipleMediaSelectionMap, setMultipleMediaSelectionMap] = useState({});
    const [isMediaSelectionOn, setMediaSelectionOn] = useState(false);

    //State media uploading
    const [showDragAndDropSection, setShowDragAndDropSection] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [fileUploadingProgress, setFileUploadingProgress] = useState({});

    useEffect(() => {
        if(allTeamMedia.length >= 0 && folderId && teamFolders.length > 0) {
            setFolderMedia([]);
            const folder = getFolderById(folderId);
            const isGeneralFolder = folder?.[FOLDER.PERMISSION_TYPE] === FOLDER_LEVEL_PERMISSIONS.PUBLIC;
            const filteredResult = filterMediaByFolderId(allTeamMedia, isGeneralFolder ? null : folderId);
            const sortedAndFilteredResult = sortMediaBySelectedOptions(filteredResult);
            setFolderMedia(sortedAndFilteredResult);
            categorizeMediaTypes(sortedAndFilteredResult);
        }
    }, [allTeamMedia, folderId, teamFolders]);

    useEffect(() => {
        setSearchFilter("");
    }, [folderId]);

    useEffect(() => {
        setFilteredMediaForSelectedTab(filteredMedia?.[selectedMediaTab]);
    }, [selectedMediaTab, filteredMedia?.[selectedMediaTab]]);

    useEffect(() => {
        let filteredResult = folderMedia;
        if(searchFilter && searchFilter.length >= 3) {
            filteredResult = filterMediaByName(searchFilter.toLowerCase(), folderMedia);
        }
        filteredResult = sortMediaBySelectedOptions(filteredResult);
        categorizeMediaTypes(filteredResult);
    }, [searchFilter, currentSort, folderMedia]);

    const fetchTeamMedia = async (teamId, isTeamOwner, user, showError) => {
        let media = teamId ? await getTeamAssets(teamId, isTeamOwner, user) : null;
        if (media?.retry) {
            setToken(teamId, "image", null);
            await fetchTeamMedia(teamId, isTeamOwner, user, showError);
        } else if (showError && media?.error) {
            throw new Error(media.error);
        }
        const themeImages = media?.assets?.filter((asset) => asset.themeName);
        setDefaultImages(themeImages);
        media = media?.assets.find((asset) => !asset.themeName).assets || [];
        const sorted = sortMedia(media, currentSort.sortBy, currentSort.sortOrder);
        setAllTeamMedia(sorted);
        setMediaLoadedForTeamId(teamId);
    };

    const loadMedia = async (teamId) => {
        if(teamId && mediaLoadedForTeamId !== teamId) {
            setLoadingMedia(true);
            try{
                await fetchTeamMedia(teamId, signedInUserIsOwner, user, true);
            } catch (err) {
                setToggleMediaError(true);
                setAllTeamMedia([]);
            }
            setLoadingMedia(false);
        }
    }

    const filterMediaByMetaData = (mediaList, metadataField, metadataValue) => {
        return mediaList.filter(media => media[metadataField] === metadataValue);
    }

    const filterMediaByFolderId = (mediaList, folderId) => {
        return filterMediaByMetaData(mediaList, MEDIA_SCHEMA.FOLDER_ID, folderId);
    }

    const filterMediaByName = (searchFilter, mediaList) => {
        return mediaList.filter(media => {
            const mediaString = JSON.stringify(media)?.toLowerCase() || "";
            return mediaString.includes(searchFilter);
        });
    }

    const categorizeMediaTypes = (mediaList) => {
        const _categorizedMedia = {
            [MEDIA_TYPES.ALL.value]: [],
            [MEDIA_TYPES.IMAGES.value]: [],
            [MEDIA_TYPES.GIFS.value]: [],
            [MEDIA_TYPES.DOCUMENTS.value]: [],
        };
        mediaList.forEach(media => {
            const name = media["blobName"] || "";
            const type = name.split(".").pop();
            _categorizedMedia[0].push(media);
            Object.values(MEDIA_TYPES).forEach((mediaType) => {
                if(mediaType["fileTypes"] && mediaType["fileTypes"].includes(type.toLowerCase())) {
                    _categorizedMedia[mediaType["value"]].push(media);
                }
            });
        });
        setFilteredMedia(_categorizedMedia);
    }

    const sortMediaBySelectedOptions = (mediaList) => {
        return sortMedia(mediaList, currentSort.sortBy, currentSort.sortOrder);
    }

    const updatedMediaSort = (sortOption) => {
        const params = SORTING_OPTIONS[sortOption].params;
        setCurrentSort({
            sortBy: params[REQUEST_PARAMS.SORT_BY],
            sortOrder: params[REQUEST_PARAMS.SORT_ORDER]
        })
    }

    const currentSortOption = useMemo(() => {
        return Object.keys(SORTING_OPTIONS).find(
            (option) =>
                SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_BY] === currentSort.sortBy &&
                SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_ORDER] === currentSort.sortOrder
        )
    }, [currentSort]);

    const selectMultipleMedia = (index, media) => {
        const _selectionMap = multipleMediaSelectionMap;
        if(!_selectionMap[index]) {
            _selectionMap[index] = media;
            setMultipleMediaSelectionMap({..._selectionMap});
        } else {      //toggle selection if already selected
            delete _selectionMap[index];
            setMultipleMediaSelectionMap({..._selectionMap});
        }
        if(Object.keys(_selectionMap).length > 0) {
            setMediaSelectionOn(true);
        } else {
            setMediaSelectionOn(false);
        }
    }

    const resetAllSelectedMedia = () => {
        setMultipleMediaSelectionMap({});
        setMediaSelectionOn(false);
    }

    const downloadMultipleMedia = async () => {
        const mediaToDownload = Object.values(multipleMediaSelectionMap);
        const downloadPromises = mediaToDownload.map((media) => downloadMedia(media));
        await Promise.all(downloadPromises);
        trackEvent(EVENTS.MEDIA_LIBRARY.DOWNLOAD_FILES, {filesCount: mediaToDownload?.length}, EVENT_TYPES.MEDIA_LIBRARY.DOWNLOAD_FILES)
    }

    const deleteMultipleMedia = async () => {
        const mediaToDelete = Object.values(multipleMediaSelectionMap);
        const promises = mediaToDelete.map((media) => softDeleteImage(selectedTeam[TEAM.ID], media));
        const responses = await Promise.all(promises);
        let failed = [];
        let _allTeamMediaList = allTeamMedia;
        responses.forEach((res, index) => {
            const media = mediaToDelete[index];
            if(res) {
                failed.push(media);
            } else {
                _allTeamMediaList = _allTeamMediaList.filter((item) => item[MEDIA_SCHEMA.SRC] !== media[MEDIA_SCHEMA.SRC]);
            }
        });
        setAllTeamMedia(_allTeamMediaList);
        trackEvent(EVENTS.MEDIA_LIBRARY.DELETE_FILES, {filesCount: mediaToDelete?.length}, EVENT_TYPES.MEDIA_LIBRARY.DELETE_FILES)
        return failed;
    }

    const canEditMedia = (media) => {
        return signedInUserIsOwner
            || isMediaOwner(media, user)
            || isUserAdmin(user);
    }

    const renameTeamMedia = async (media, newName) => {
      media = { ...media, [MEDIA_SCHEMA.NAME]: newName };
      const error = await renameMedia(selectedTeam[TEAM.ID], media);
      if (!error) {
        const updatedMediaList = allTeamMedia.map((item) =>
          item[MEDIA_SCHEMA.SRC] === media[MEDIA_SCHEMA.SRC]
            ? { ...item, [MEDIA_SCHEMA.NAME]: newName }
            : item
        );
        setAllTeamMedia(updatedMediaList);
      }
      return error;
    };

    const deleteTeamMedia = async (media) => {
        const error = await softDeleteImage(selectedTeam[TEAM.ID], media);
        if(!error){
            const updatedMediaList = allTeamMedia.filter((item) =>
                item[MEDIA_SCHEMA.SRC] !== media[MEDIA_SCHEMA.SRC]
            );
            setAllTeamMedia(updatedMediaList);
        }
        return error;
    };

    const uploadTeamMedia = async (files, setProgress) => {
        let updatedMediaList = [...allTeamMedia];
        const folder = getFolderById(folderId);
        const isGeneralFolder = folder?.[FOLDER.PERMISSION_TYPE] === FOLDER_LEVEL_PERMISSIONS.PUBLIC;
        const folderIdForMedia = isGeneralFolder ? null : folderId;
        let error = false;
        const uploads = [];
        await files?.forEach((file) => {
            //update file upload progress initially
            fileUploadingProgress[file.name] = {
                fileName: file.name,
                value: 0,
            }
            uploads.push(
                uploadWithProgress(
                    selectedTeam[TEAM.ID],
                    user,
                    file,
                    setProgress,
                    {
                        [MEDIA_SCHEMA.FOLDER_ID]: folderIdForMedia,
                    },
                    "image",
                )
            );
        });
        setFileUploadingProgress(_.cloneDeep(fileUploadingProgress));
        let filesToRetry = [];
        const res = await Promise.all(uploads);
        res.forEach((response, index) => {
            if (response?.retry) {
                filesToRetry.push(files[index]);
            } else if (!response?.error) {
                const filePath = `${Configs?.REACT_APP_IMAGE_STORAGE_URL}/${response?.sas_container}/${response?.fileName}`;
                updatedMediaList.unshift({
                    [MEDIA_SCHEMA.SRC]: filePath,
                    [MEDIA_SCHEMA.NAME]: response?.sanitizedfileName,
                    [MEDIA_SCHEMA.CREATED_ON]: Date.now(),
                    [MEDIA_SCHEMA.CAN_EDIT]: true,
                    [MEDIA_SCHEMA.BLOB_NAME]: response?.blob,
                    [MEDIA_SCHEMA.FOLDER_ID]: folderIdForMedia,
                    [MEDIA_SCHEMA.OWNER]: user?.[JWT.UPN],
                });
            } else {
                error = true;
            }
        });
        if (filesToRetry?.length) {
            setToken(selectedTeam[TEAM.ID], "image", null); // clear the token
            return await uploadTeamMedia(filesToRetry); // retry
        } else {
            setAllTeamMedia([...updatedMediaList]);
            return error;
        }
    }

    const handleFileUpload = async (files) => {
        if(files && files.length > 0) {
            setFileUploading(true);
            try {
                const error = await uploadTeamMedia(
                    files,
                    setFileUploadingProgress,
                );
                if (error) {
                    throw new Error();
                } else {
                    setSnackMessage("Files uploaded successfully.");
                    setSnackType(SNACK_TYPES.SUCCESS);
                    trackEvent(EVENTS.MEDIA_LIBRARY.UPLOAD_FILES, {filesCount: files?.length}, EVENT_TYPES.MEDIA_LIBRARY.UPLOAD_FILES)
                }
            } catch (error) {
                setSnackMessage("Files uploaded successfully.");
                setSnackType(SNACK_TYPES.SUCCESS);
            }
            setTimeout(() => {
                setFileUploadingProgress({});
                setFileUploading(false);
            }, 3000);
            return;
        }
    }

    const setErrorMessage = (message) => {
        setSnackMessage(message);
        setSnackType(SNACK_TYPES.ERROR);
    }

    const setSuccessMessage = (message) => {
        setSnackMessage(message);
        setSnackType(SNACK_TYPES.SUCCESS);
    }

    const initialState = {
        allTeamMedia,
        selectedMediaTab,
        setSelectedMediaTab,
        loadingMedia,
        setFolderId,
        toggleMediaError,
        defaultImages,
        loadMedia,
        selectedTeam,
        filteredMedia,
        filteredMediaForSelectedTab,
        searchFilter,
        setSearchFilter,
        updatedMediaSort,
        currentSort,
        currentSortOption,
        selectedMediaForPreviewIndex,
        setSelectedMediaForPreviewIndex,
        multipleMediaSelectionMap,
        selectMultipleMedia,
        setMultipleMediaSelectionMap,
        isMediaSelectionOn,
        resetAllSelectedMedia,
        renameTeamMedia,
        deleteTeamMedia,
        downloadMultipleMedia,
        deleteMultipleMedia,
        canEditMedia,
        uploadTeamMedia,
        showDragAndDropSection,
        setShowDragAndDropSection,
        handleFileUpload,
        fileUploading,
        setFileUploading,
        fileUploadingProgress,
        setFileUploadingProgress,
        setErrorMessage,
        setSuccessMessage,
    }

    return <Context.Provider value={initialState}>{children}</Context.Provider>
}

export default MediaLibrary;