import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  executeCampaign,
  getCampaign,
  getCampaignMetaData,
  getTemplate,
  postCampaign,
  previewTemplate,
  updateCampaign,
  updateTemplate,
  testCampaign,
  executeCampaignV2,
  getCampaignExecutionsDetail
} from "../APIs/CampaignAPI";
import { useAppContext } from "./Core";
import {
  getRecipientsFieldValues,
  getCampaignStatuses,
  getNotification,
  getCampaignType,
  checkIfExecutable,
  getConfigurationFieldValues,
  addAConfiguredZoomBot,
  validateField,
  convertTemplateContentToString,
  userHasOwnerPrivileges,
  validateEmail,
  validateStringLength,
  getCampaignAttachmentsSize,
  cleanUpAttachments,
} from "../utils/campaign";
import {
  updateRecipientField,
  updateOrCreateNotification,
  updateNotificationConfig,
  updateMapping,
  removeNotificationAtIndex,
  getConfigIndex,
  getCurrentStepIndex,
  getStepperConfig,
  removeEmbeddedTemplates,
  getEmbeddedTemplates,
  addEmbeddedTemplates,
  updateCronTrigger,
  autoMapRecipientField,
  resetRecipients,
  updateFieldErrors,
  removeUnusedMappings,
} from "../utils/campaignEdit";
import {
  getChannel,
  getPriority,
  isNotificationTypeAllowed,
} from "../utils/notification";
import {
  downloadCSV,
  getUploadedCSVFileName,
  removeUploadedCSVFileName,
} from "../utils/blobStorage";
import {
  CAMPAIGN_STATUSES,
  NOTIFICATION_CHANNELS,
  UI_DATA,
  SUBJECT_MAX_CHAR,
  SNIPPET_MAX_CHAR,
  DISPLAY_NAME_MAX_CHAR,
  CAMPAIGN_FIELDS,
  CODES,
  API_VERSION
} from "../consts/Campaign";
import { JWT } from "../consts/AuthFields";
import {
  CAMPAIGN,
  CONFIGURATIONS,
  DATA_SOURCE,
  EXTERNAL_USER_OPTIONS_CONFIGS,
  FOLDER,
  MAPPING,
  NOTIFICATION,
  PRIORITIES,
  RECIPIENTS,
  TEAM,
  TEMPLATE,
  TRACKING_CONFIGS,
} from "../consts/DBFields";
import {
  ROUTES,
  ERROR_MSG,
  SUCCESS_MSG,
  SNACK_TYPES,
  INFO_MSG,
  FEATURE,
  REVIEW_STEP_INDEX,
  EVENTS,
  EVENT_TYPES,
} from "../consts/Common";
import { DEFAULT_FLOW } from "../consts/CampaignEdit";
import { CAMPAIGN_TYPES } from "../consts/Campaign";
import {
  isSignedInUserATeamOwner,
  isUserSuperAdmin,
  isUserAdmin,
  isUserCampaignOwner,
} from "../utils/user";
import {
  makeTemplateFieldName,
  removeInvalidDynamicContent,
} from "../utils/template";
import { getTemplateMustacheVariables } from "../components/template/EmailEditor/grapesjs/util";
import { useCampaignsContext } from "./CampaignsList";
import { postTeamTemplate } from "../APIs/TemplatesAPI";
import useFolder from "../hooks/useFolder";
import _ from "lodash";
import { FOLDER_LEVEL_PERMISSIONS, FOLDER_LEVEL_ROLES } from "../consts/Folders";
import { canEditCampagin } from "../utils/folderUtils";
import Configs from "../Configurations";
import { useFoldersContext } from "./FoldersList";
import { trackEvent } from "../utils/appInsight";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const CampaignState = Context.Consumer;
export const useCampaignContext = () => useContext(Context);

let originalCampaign = {}; // use this to determine whether a campaign has been edited

// Create and export the provider, which defines and controls the state
function Campaign({ children }) {
  const _isMounted = useRef(true);
  const history = useHistory();
  const { campaign_uid, folder_id} = useParams();
  const { state } = useLocation();
  const {
    user,
    selectedTeam,
    snackMessage,
    setSnackMessage,
    setSnackType,
    teamUsers,
    getMembersOfTeam,
    userTeams,
    isToggleEnabled,
    pageTitle
  } = useAppContext();

  const {
    setCurrentFolder,
    currentFolder,
  } = useFoldersContext();

  const {
    folder: selectedFolder,
    getFolder,
    getIsFolderOwner,
    getUserRole,
  } = useFolder();

  const { setStatusTabOverride, fetchCampaigns } = useCampaignsContext();
  const [userCanEdit, toggleUserCanEdit] = useState(false);
  const [channelAllowed, toggleChannelAllowed] = useState(false);
  const [loadingCampaign, toggleLoadingCampaign] = useState(true);
  const [campaignError, setCampaignError] = useState(null);
  const [steps, setSteps] = useState(DEFAULT_FLOW); // the edit flow stepper
  const [fieldErrors, setFieldErrors] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignType, setCampaignType] = useState(); // general or personalized
  const [isExecutable, toggleIsExecutable] = useState(false);
  const [csvFile, setCsvFile] = useState();
  const [dataSourceHeadings, setDataSourceHeadings] = useState([]);
  const [notificationIndex, setNotificationIndex] = useState(0);
  const [channel, setChannel] = useState(); // mail, zoom, teams
  const [downloadError, setDownloadError] = useState(false);
  const [isDownloading, toggleIsDownloading] = useState(false);
  // notification details
  const [to, setTo] = useState();
  const [cc, setCc] = useState();
  const [bcc, setBcc] = useState();
  const [from, setFrom] = useState();
  const [fromDisplayName, setFromDisplayName] = useState();
  const [subject, setSubject] = useState();
  const [snippet, setSnippet] = useState();
  const [priority, setPriority] = useState();
  const [attachments, setAttachments] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templatePreview, setTemplatePreview] = useState();
  // tracking configs
  const [clickTracking, setClickTracking] = useState();
  const [viewTracking, setViewTracking] = useState();
  const [advancedMetrics, setAdvancedMetrics] = useState();
  const [trackLocation, setTrackLocation] = useState();
  const [savingCampaign, toggleSavingCampaign] = useState(false);
  const [needToSaveTemplate, setNeedToSaveTemplate] = useState(false);
  const [campaignExecutions, setCampaignExecutions] = useState([]);

  const trackingMetricSetter = {
    [TRACKING_CONFIGS.VIEW]: setViewTracking,
    [TRACKING_CONFIGS.CLICK]: setClickTracking,
    [TRACKING_CONFIGS.ADVANCED_METRICS]: setAdvancedMetrics,
    [TRACKING_CONFIGS.TRACK_LOCATION]: setTrackLocation,
  };

  // external users options config
  const [hasExternalUsers, setHasExternalUsers] = useState()

  useEffect(() => {
    if (userTeams?.length) {
      selectCampaign();
      return () => {
        _isMounted.current = false;
      };
    }
  }, [campaign_uid, userTeams]);

  useEffect(() => {
    if (campaignError) {
      setSnackMessage(campaignError);
      setSnackType(SNACK_TYPES.ERROR);
    }
  }, [campaignError]);

  useEffect(() => {
    if (!snackMessage) {
      setCampaignError(null);
    }
  }, [snackMessage]);

  useEffect(() => {
    // check executable status when required fields are edited
    if (selectedCampaign) {
      toggleIsExecutable(checkIfExecutable(selectedCampaign, needToSaveTemplate, isToggleEnabled(FEATURE.EXTERNAL_USERS_CHECK), campaignType));
    }
  }, [
    to,
    from,
    fromDisplayName,
    subject,
    selectedTemplate,
    selectedCampaign?.[CAMPAIGN.METADATA]?.[CAMPAIGN.METADATA_HEADINGS],
    needToSaveTemplate,
    hasExternalUsers
  ]);

  // look for an existing csv upload if campaign type is personalized
  useEffect(() => {
    if (campaignType === CAMPAIGN_TYPES.personalized.value) {
      saveCampaign(
        {
          ...selectedCampaign,
          general_data_source_type: null,
        },
        false
      );
    }
    getExistingCsv(selectedCampaign);
  }, [campaignType]);

  const createCampaign = async (name, categoryId) => {
    setCampaignError(null);
    const teamId = selectedTeam[TEAM.ID];
    const userUpn = user[JWT.UPN];    
    if (name && categoryId && teamId && userUpn && folder_id) {
      try {
        const createdId = await postCampaign({
          [CAMPAIGN.NAME]: name.trim(),
          [CAMPAIGN.CATEGORY_ID]: Number(categoryId),
          [CAMPAIGN.FOLDER_ID]: folder_id,
          [CAMPAIGN.IS_DRAFT]: true,
          [CAMPAIGN.TEAM_ID]: teamId,
          [CAMPAIGN.OWNER]: userUpn,
          [CAMPAIGN.NOTIFICATIONS]: [{}],
          general_data_source_type: null,
        });
        history.push(`/${ROUTES.CAMPAIGN}/${createdId}`, { from: "/" });
      } catch (error) {
        let message = ERROR_MSG.POST_CAMPAIGN;
        if (error.response?.data?.code === CODES.CAMPAIGN_NAME_TAKEN) {
          message = ERROR_MSG.CAMPAIGN_NAME_TAKEN;
        }
        setCampaignError(message);
        return error;
      }
    }
  };

  // fetches a campaign by id and sets campaign state with the campaign's values
  const selectCampaign = async () => {
    setCampaignError(null);
    let campaignToSelect = {};
    let channel;
    let campaignType;
    if (campaign_uid) {
      try {
        campaignToSelect = await getCampaign(campaign_uid);
        originalCampaign = _.cloneDeep(campaignToSelect);
      } catch (err) {
        if (_isMounted.current) {
          originalCampaign = null;
          setSelectedCampaign(null);
          toggleLoadingCampaign(false);
          trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: err?.message, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.GET_CAMPAIGN_ERROR)
          return;
        }
      }
      if (_isMounted.current) {
        const teamId = campaignToSelect?.[CAMPAIGN.TEAM_ID];
        let teamMembers = teamUsers?.hasOwnProperty(teamId)
          ? teamUsers[teamId]
          : await getMembersOfTeam(teamId);
        const folderId = campaignToSelect?.[CAMPAIGN.FOLDER_ID];
        const folder = await getFolder(folderId);
        setCurrentFolder(folder);
        const isFolderOwner = getIsFolderOwner(user, folder[FOLDER.PERMISSIONS]);
        const folderUserRole = getUserRole(user, folder[FOLDER.PERMISSIONS]);
        const signedInUserIsOwner = isSignedInUserATeamOwner(user, teamMembers);
        const folderType = folder[FOLDER.PERMISSION_TYPE];
        
        let isOwner = canEditCampagin(user, signedInUserIsOwner, campaignToSelect, isFolderOwner, folderUserRole, folderType);

        // if no notification exists yet, create it
        campaignToSelect = updateOrCreateNotification(
          campaignToSelect,
          notificationIndex
        );
        channel = getChannel(
          getNotification(campaignToSelect, notificationIndex)
        );
        let campaignTeam = selectedTeam;
        if (teamId !== selectedTeam?.[TEAM.ID]) {
          campaignTeam =
            userTeams?.find((team) => team[TEAM.ID] === teamId) || selectedTeam;
        }
        const typeAllowed = isNotificationTypeAllowed(channel, campaignTeam);
        toggleUserCanEdit(isOwner && typeAllowed);
        toggleChannelAllowed(typeAllowed);
        if (
          channel === NOTIFICATION_CHANNELS.teams.value
        ) {
          campaignType = CAMPAIGN_TYPES.personalized.value;
        } else {
          campaignType = getCampaignType(campaignToSelect, notificationIndex);
        }
        // configure the stepper
        const stepper = getStepperConfig(channel, campaignType);
        setSteps(stepper);
        const landingStep = getCurrentStepIndex(campaignToSelect, stepper);
        if (isOwner && typeAllowed) {
          setCurrentStep(
            state?.duplicate && landingStep !== stepper?.length - 1
              ? 0
              : landingStep
          );
        } else {
          setCurrentStep(stepper?.length - 1);
        }
      }
    }
    setSelectedCampaign(campaignToSelect);
    setChannel(channel);
    setCampaignType(campaignType);
    await setNotificationDetails(campaignToSelect, channel, campaignType);
    if (_isMounted.current) {
      // if campaign has been sent, redirect to the overview page
      const statuses = getCampaignStatuses(campaignToSelect) || [];
      if (!state?.isResend && statuses.includes(CAMPAIGN_STATUSES.SENT.value)) {
        toggleLoadingCampaign(false);
        history.replace({
          pathname: `/${ROUTES.CAMPAIGN}/${campaign_uid}`,
          state: { sent: true, from: history.location?.state?.from },
        });
        await loadCampaignExecutions();
        return;
      }
      toggleIsExecutable(checkIfExecutable(campaignToSelect, needToSaveTemplate, isToggleEnabled(FEATURE.EXTERNAL_USERS_CHECK), campaignType));
      toggleLoadingCampaign(false);
    }
  };

  const saveCampaign = async (campaign, showSuccessMessage = true) => {
    const canSaveCampaign = await editCampaignPermissionCheck(campaign);
    if(!canSaveCampaign) return;
    setCampaignError(null);
    toggleSavingCampaign(true);
    try {
      let campaignToSave = _.cloneDeep(campaign || selectedCampaign);
      if (
        channel &&
        channel !== NOTIFICATION_CHANNELS.mail.value &&
        campaignType &&
        campaignType !== CAMPAIGN_TYPES.personalized.value
      ) {
        campaignToSave[CAMPAIGN.DATA_SOURCE] = {
          [DATA_SOURCE.TYPE]:
            CAMPAIGN_TYPES.personalized.dataSourceTypes[
              CAMPAIGN_TYPES.personalized.defaultType
            ].value,
        };
      }
      const embeddedTemplates = getEmbeddedTemplates(campaignToSave);
      campaignToSave = await updateVariableMapping(campaignToSave);
      await updateCampaign(campaignToSave);
      if (isToggleEnabled(FEATURE.EMAIL_ATTACHMENTS)) {
        cleanUpAttachments(campaignToSave, attachments);
      }
      if (showSuccessMessage) {
        setSnackMessage(SUCCESS_MSG.PUT_CAMPAIGN);
        setSnackType(SNACK_TYPES.SUCCESS);
      }
      campaignToSave = await addEmbeddedTemplates(
        campaignToSave,
        embeddedTemplates
      );
      originalCampaign = _.cloneDeep(campaignToSave);
      setSelectedCampaign(campaignToSave);
      toggleSavingCampaign(false);
    } catch (error) {
      let message = ERROR_MSG.PUT_CAMPAIGN;
      if (error.response?.data?.code === CODES.CAMPAIGN_NAME_TAKEN) {
        message = ERROR_MSG.CAMPAIGN_NAME_TAKEN;
      }
      setCampaignError(message);
      toggleSavingCampaign(false);
      trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: message, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.SAVE_CAMPAIGN_ERROR)
      return error;
    }
  };

  const loadCampaignExecutions = async () => {
    if(campaign_uid) {
      const rawCampaignExecutions = await getCampaignExecutionsDetail(campaign_uid, { onlyCampaignExecutions: true });
      setCampaignExecutions(rawCampaignExecutions || []);
    }
  }

  //Only "Admin", "Team Owner", "Folder Owner", "General folder Creator", "Private folder Creator with Folder Edit access" can save/update a campaign
  const editCampaignPermissionCheck = async (campaign) => {
    const teamId = campaign?.[CAMPAIGN.TEAM_ID];
    let teamMembers = teamUsers?.hasOwnProperty(teamId)
          ? teamUsers[teamId]
          : await getMembersOfTeam(teamId);
    let folder = selectedFolder;
    if(_.isEmpty(folder) || folder[FOLDER.FOLDER_ID] !== campaign?.[CAMPAIGN.FOLDER_ID]) {
      const folderId = campaign?.[CAMPAIGN.FOLDER_ID];
      folder = await getFolder(folderId);
    }
    const isFolderOwner = getIsFolderOwner(user, folder[FOLDER.PERMISSIONS]);
    const folderUserRole = getUserRole(user, folder[FOLDER.PERMISSIONS]);
    const signedInUserIsOwner = isSignedInUserATeamOwner(user, teamMembers);
    const folderType = folder[FOLDER.PERMISSION_TYPE];
    return canEditCampagin(user, signedInUserIsOwner, campaign, isFolderOwner, folderUserRole, folderType);
  }

  const updateVariableMapping = async (campaign) => {
    let dataSourceHeadingsCopy = [...dataSourceHeadings];
    let campaignToSave = _.cloneDeep(campaign);
    campaignToSave[CAMPAIGN.IS_DRAFT] = !checkIfExecutable(campaignToSave, needToSaveTemplate, isToggleEnabled(FEATURE.EXTERNAL_USERS_CHECK), campaignType);
    if (
      campaignType === CAMPAIGN_TYPES.personalized.value ||
      campaign?.general_data_source_type === "csv"
    ) {
      [to, cc, bcc].forEach((recipientValue) => {
        campaignToSave = autoMapRecipientField(
          campaignToSave,
          notificationIndex,
          recipientValue
        );
      });
      let variables = getTemplateMustacheVariables(
        selectedTemplate?.[TEMPLATE.CONTENT]
      );
      Object.keys(variables)?.forEach((templateFieldName) => {
        const cip = variables[templateFieldName].cip;
        campaignToSave = updateMapping(
          campaignToSave,
          notificationIndex,
          templateFieldName,
          templateFieldName,
          cip ? MAPPING.CIP : null,
          cip ? to : variables[templateFieldName].srcname
        );
      });

      // This is for CSV campaigns where we need to re-make data on review step
      if (dataSourceHeadings.length === 0) {
        if( (currentStep === REVIEW_STEP_INDEX.CHANNEL_MAIL && channel === NOTIFICATION_CHANNELS.mail.value) || 
            (currentStep === REVIEW_STEP_INDEX.CHANNEL_TEAMS && channel === NOTIFICATION_CHANNELS.teams.value)
        ) {
          console.log("data source heading updated - campaignContext");
          const headings = await updateDataSourceHeadings(campaignToSave);
          dataSourceHeadingsCopy = [...headings];  
        }
      }
    }
    if (fromDisplayName && !isToggleEnabled(FEATURE.USE_FROM_DISPLAY_NAME)) {
      campaignToSave = updateNotificationConfig(
        campaignToSave,
        notificationIndex,
        CONFIGURATIONS.FROM_DISPLAY_NAME,
        null
      );
      setFromDisplayName(null);
    }
    campaignToSave = await removeEmbeddedTemplates(campaignToSave);
    campaignToSave = await removeUnusedMappings(
      campaignToSave,
      dataSourceHeadingsCopy
    );
    return campaignToSave;
  };

  const sendCampaign = async (scheduler) => {
    let error;
    let shouldExecuteV2API = false;
    const teamIdsMappedV2API = Configs.REACT_APP_V2_TEST_TEAM_IDS?.split(",")?.filter(teamId => teamId);
    const apiVersion = Configs.API_VERSION;
    setCampaignError(null);
    let campaignToSave = await updateVariableMapping(selectedCampaign);
    try {
      if (scheduler) {
        campaignToSave = updateCronTrigger(campaignToSave, scheduler);
      }
      await updateCampaign(campaignToSave);
      if (!scheduler) {
        shouldExecuteV2API = campaignToSave[CAMPAIGN.TEAM_ID] && 
        apiVersion?.toLowerCase()===API_VERSION.API_VERSION_V2 && teamIdsMappedV2API && 
        ( _.isEmpty(teamIdsMappedV2API) || _.includes(teamIdsMappedV2API, campaignToSave[CAMPAIGN.TEAM_ID]) );

        if(isToggleEnabled(FEATURE.USE_V1)){
          shouldExecuteV2API = false;
        }

        if(Configs.REACT_ENV === 'local'){
          shouldExecuteV2API = true;
        }

        if(shouldExecuteV2API) {
          await executeCampaignV2(campaignToSave[CAMPAIGN.UID]);
        }
        else {
          await executeCampaign(campaignToSave[CAMPAIGN.UID]);
        }
        campaignToSave[CAMPAIGN.DATE_LAST_EXECUTED] = new Date();
      }
      let campaignConfigs = getConfigurationFieldValues(selectedCampaign, notificationIndex)
      trackEvent(EVENTS.USER_INTERACTION, {
        campaignId: selectedCampaign?.uid,
        campaignType: campaignType,
        trackingConfigs:{
          trackClick:campaignConfigs?.trackClick,
          trackOpens:campaignConfigs?.trackView,
          trackLocations:campaignConfigs?.trackClickLocations,
          advancedTracking: campaignConfigs?.trackViewAdvancedMetrics
        }
      },
      EVENT_TYPES.USER_INTERACTION.SEND_CAMPAIGN)
      trackEvent(
        EVENTS.TEAM.TEAM_ACTIVITY,
        {
          teamName: selectedTeam[TEAM.NAME],
          teamId: selectedTeam[TEAM.ID],
          folderId: currentFolder[FOLDER.FOLDER_ID],
          folderName: currentFolder[FOLDER.NAME],
        },
        EVENT_TYPES.TEAM_ACTIVITY.SEND_MESSAGE,
      );
      const updatedCampaign = await getCampaign(campaignToSave[CAMPAIGN.UID]);
      campaignToSave = { ...campaignToSave, ...updatedCampaign };
      error = false;
    } catch (err) {
      error = true;
      trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: err?.message, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.SEND_CAMPAIGN_ERROR)
    } finally {
      setSelectedCampaign(campaignToSave);
    }
    return error;
  };

  const scheduleCampaign = async (cronExp) => {
    const error = await sendCampaign(cronExp);
    if (error) {
      setSnackMessage(ERROR_MSG.SCHEDULE_CAMPAIGN);
      setSnackType(SNACK_TYPES.ERROR);
      trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: ERROR_MSG.SCHEDULE_CAMPAIGN, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.SCHEDULE_CAMPAIGN_ERROR)
    } else {
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.SCHEDULE_CAMPAIGN);
    }
    return error;
  };

  const cancelCampaignSchedule = async (campaign) => {
    if (campaign) {
      try {
        const campaignToSave = updateCronTrigger(campaign, null);
        await updateCampaign(campaignToSave);
        setSnackMessage(INFO_MSG.CANCEL_SCHEDULE);
        setSnackType(SNACK_TYPES.INFO);
        originalCampaign = _.cloneDeep(campaignToSave);
        let sent = campaignToSave?.[CAMPAIGN.DATE_LAST_EXECUTED] ? true : false;
        history.push({
          pathname: `/${ROUTES.CAMPAIGN}/${campaignToSave[CAMPAIGN.UID]}`,
          state: { isResend: sent, from: "/" },
        });
      } catch (err) {
        setSnackMessage(ERROR_MSG.CANCEL_SCHEDULE);
        setSnackType(SNACK_TYPES.ERROR);
      }
    }
    return;
  };

  const sendCampaignTest = async (recipients = [], variables = {}) => {
    setCampaignError(null);
    try {
      if (!selectedCampaign) {
        throw new Error();
      }
      let bodyContent;
      await saveCampaign(selectedCampaign, false);
      const template = await getTemplate(
        selectedCampaign[CAMPAIGN.UID],
        channel,
        channel === NOTIFICATION_CHANNELS.mail.value ? true : false
      );
      if (!template) {
        throw new Error();
      }
      if (channel === NOTIFICATION_CHANNELS.mail.value) {
        bodyContent = template?.[TEMPLATE.HTML];
      } else {
        bodyContent = template?.[TEMPLATE.CONTENT];
        // needed for teams notification type
        if (bodyContent && typeof bodyContent === "string") {
          const parsedContent = JSON.parse(bodyContent);
          if (parsedContent) {
            bodyContent = parsedContent;
          }
        }
      }
      if (snippet) {
        variables[UI_DATA.SNIPPET_TEXT] = snippet;
      }
      let params = {
        to: recipients,
        [channel === NOTIFICATION_CHANNELS.mail.value ? "body" : "content"]:
          bodyContent,
        variables: variables,
        istestmessage: true
      };
      if (subject) {
        params.subject = subject;
      }
      let fromAddress = from ? from : user[JWT.UPN];
      if (fromDisplayName && isToggleEnabled(FEATURE.USE_FROM_DISPLAY_NAME)) {
        params.from = `${fromDisplayName} <${fromAddress}>`;
      } else {
        params.from = fromAddress;
      }

      // including attachments in the test notification
      if (attachments) {
        params.attachments = attachments;
      }
      await testCampaign(selectedCampaign[CAMPAIGN.UID], params);
      try {
        const updatedCampaign = await getCampaign(selectedCampaign[CAMPAIGN.UID]);
        setSelectedCampaign({ ...selectedCampaign, ...updatedCampaign });
        setSnackType(SNACK_TYPES.SUCCESS);
        setSnackMessage(SUCCESS_MSG.TEST_CAMPAIGN);
      }
      catch(err) {
        setSnackType(SNACK_TYPES.ERROR);
      } 
    } catch (err) {
      setCampaignError(ERROR_MSG.TEST_CAMPAIGN);
      trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: ERROR_MSG.TEST_CAMPAIGN, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.SEND_TEST_CAMPAIGN_ERROR)
    }
  };

  const saveTemplate = async ({
    content = "",
    isNewTemplate,
    showMessage = true,
  }) => {
    setCampaignError(null);
    try {
      await updateTemplate(
        {
          [TEMPLATE.CONTENT]:
            channel === NOTIFICATION_CHANNELS.mail.value
              ? content
              : JSON.parse(content),
        },
        selectedCampaign[CAMPAIGN.UID],
        channel
      );
      getTemplatePreview(selectedCampaign, content);
      const updatedTemplate = await getTemplate(
        selectedCampaign[CAMPAIGN.UID],
        channel
      );
      let notification =
        getNotification(selectedCampaign, notificationIndex) || {};
      notification[NOTIFICATION.TEMPLATE] = updatedTemplate;
      const updatedCampaign = selectedCampaign;
      updatedCampaign[CAMPAIGN.NOTIFICATIONS][notificationIndex] = notification;
      setSelectedCampaign(updatedCampaign);
      setSelectedTemplate(convertTemplateContentToString(updatedTemplate));
      if (!isNewTemplate && showMessage) {
        setSnackMessage(SUCCESS_MSG.PUT_TEMPLATE);
        setSnackType(SNACK_TYPES.SUCCESS);
      }
    } catch (err) {
      setCampaignError(ERROR_MSG.PUT_TEMPLATE);
      trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: ERROR_MSG.PUT_TEMPLATE, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.SAVE_TEMPLATE_ERROR)
    }
  };

  const setNotificationDetails = async (campaign, channel, campaignType) => {
    let recipients = {};
    let configurations = {};
    let template;
    if (campaign) {
      recipients = getRecipientsFieldValues(
        campaign,
        notificationIndex,
        csvFile
      );
      configurations = getConfigurationFieldValues(campaign, notificationIndex);
      if (channel) {
        try {
          template = await getTemplate(campaign[CAMPAIGN.UID], channel);
        } catch (err) {
          // no template created yet
        }
      }
      getTemplatePreview(campaign, template?.[TEMPLATE.CONTENT]);
    }
    if (_isMounted.current) {
      let errors = [];
      setTo(recipients[RECIPIENTS.TO]);
      setCc(recipients[RECIPIENTS.CC]);
      setBcc(recipients[RECIPIENTS.BCC]);
      if (
        campaignType === CAMPAIGN_TYPES.general.value &&
        campaign?.general_data_source_type !== "csv"
      ) {
        if (
          recipients[RECIPIENTS.TO] &&
          !validateEmail(recipients[RECIPIENTS.TO])
        ) {
          errors.push(RECIPIENTS.TO);
        }
        if (
          recipients[RECIPIENTS.CC] &&
          !validateEmail(recipients[RECIPIENTS.CC])
        ) {
          errors.push(RECIPIENTS.CC);
        }
        if (
          recipients[RECIPIENTS.BCC] &&
          !validateEmail(recipients[RECIPIENTS.BCC])
        ) {
          errors.push(RECIPIENTS.BCC);
        }
      }
      setFrom(configurations[CONFIGURATIONS.FROM]);
      setFromDisplayName(configurations[CONFIGURATIONS.FROM_DISPLAY_NAME]);
      if (isToggleEnabled(FEATURE.USE_FROM_DISPLAY_NAME)) {
        if (
          !validateStringLength(
            configurations[CONFIGURATIONS.FROM_DISPLAY_NAME],
            DISPLAY_NAME_MAX_CHAR
          )
        ) {
          errors.push(CONFIGURATIONS.FROM_DISPLAY_NAME);
        }
      }
      setSubject(configurations[CONFIGURATIONS.SUBJECT]);
      if (
        !validateStringLength(
          configurations[CONFIGURATIONS.SUBJECT],
          SUBJECT_MAX_CHAR
        )
      ) {
        errors.push(CONFIGURATIONS.SUBJECT);
      }
      setPriority(configurations[CONFIGURATIONS.PRIORITY]);
      setSnippet(configurations[UI_DATA.SNIPPET_TEXT]);
      if (
        !validateStringLength(
          configurations[UI_DATA.SNIPPET_TEXT],
          SNIPPET_MAX_CHAR
        )
      ) {
        errors.push(UI_DATA.SNIPPET_TEXT);
      }
      setAttachments(configurations[CONFIGURATIONS.ATTACHMENTS]);
      setClickTracking(configurations[TRACKING_CONFIGS.CLICK]);
      setViewTracking(configurations[TRACKING_CONFIGS.VIEW]);
      setAdvancedMetrics(configurations[TRACKING_CONFIGS.ADVANCED_METRICS]);
      setTrackLocation(configurations[TRACKING_CONFIGS.TRACK_LOCATION]);
      setHasExternalUsers(configurations[EXTERNAL_USER_OPTIONS_CONFIGS.INCLUDE_UNSUBSCRIBE_LINK]);
      setSelectedTemplate(
        template ? convertTemplateContentToString(template) : null
      );
      setFieldErrors(errors);
    }
  };

  const getTemplatePreview = async (campaign, templateContent) => {
    let markup;
    try {
      if (
        getNotification(campaign, notificationIndex)?.[NOTIFICATION.TYPE] ===
        NOTIFICATION_CHANNELS.mail.value
      ) {
        const variableList =
          getTemplateMustacheVariables(templateContent) || {};
        const variables = {};
        Object.keys(variableList)?.forEach((varName) => {
          variables[varName] = `{{${variableList[varName].srcname}}}`;
        });
        markup = await previewTemplate(campaign[CAMPAIGN.UID], variables);
      } else {
        throw new Error("Can only get preview markup for email notifications.");
      }
    } catch (err) {
      markup = "";
    }
    if (_isMounted.current) {
      setTemplatePreview(markup);
    }
  };

  // if a csv has already been uploaded for the campaign, fetch it
  const getExistingCsv = async (campaign) => {
    let file = "";
    if (campaign) {
      file = await getUploadedCSVFileName(
        campaign[CAMPAIGN.TEAM_ID],
        campaign[CAMPAIGN.UID]
      );
    }
    setCsvFile(file);
    await updateDataSourceHeadings();
  };

  // if a csv is present, remove the same
  const removeExistingCsvIfPresent = async (campaign) => {
    if (campaign) {
      let removed = await removeUploadedCSVFileName(
        campaign[CAMPAIGN.TEAM_ID],
        campaign[CAMPAIGN.UID]
      );
      if (removed) {
        setCsvFile(null);
        await updateDataSourceHeadings();
      }
    }
  };

  const updateDataSourceHeadings = async (campaign, resetSettings = false) => {
    let headings = [];
    const updatedCampaign = _.cloneDeep(campaign) || selectedCampaign || {};
    if (updatedCampaign) {
      try {
        let metaData = updatedCampaign[CAMPAIGN.UID]
          ? await getCampaignMetaData(updatedCampaign[CAMPAIGN.UID])
          : [];

        metaData = metaData.map((heading) => heading.trim());
        if (
          updatedCampaign?.data_source?.type === "csv" ||
          updatedCampaign?.general_data_source_type === "csv"
        ) {
          validatePersonalizedFields(metaData);
        }

        if (metaData.length) {
          updatedCampaign[CAMPAIGN.METADATA] = {
            ...updatedCampaign?.[CAMPAIGN.METADATA],
            [CAMPAIGN.METADATA_HEADINGS]: metaData,
          };
          metaData.map((heading) =>
            headings.push({
              value: heading.trim(),
              label: heading.trim(),
              variable: makeTemplateFieldName(heading),
              type: "csv",
            })
          );
        } else {
          updatedCampaign[CAMPAIGN.METADATA] = {
            ...updatedCampaign?.[CAMPAIGN.METADATA],
            [CAMPAIGN.METADATA_HEADINGS]: [],
          };
        }
        let templateContent = selectedTemplate?.[TEMPLATE.CONTENT];
        if (!templateContent) {
          const template = await getTemplate(
            updatedCampaign[CAMPAIGN.UID],
            channel
          );
          templateContent = template?.[TEMPLATE.CONTENT];
        }
        removeDeprecatedMustacheVariables(templateContent, headings);
        setSelectedCampaign(updatedCampaign);
      } catch (err) {
        // no meta data exists
      }
    }
    setDataSourceHeadings(headings);
    if (resetSettings) {
      // reset configs that use data headings
      let notification = getNotification(updatedCampaign, notificationIndex);
      if (notification) {
        updatedCampaign[CAMPAIGN.NOTIFICATIONS][notificationIndex][
          NOTIFICATION.RECIPIENTS
        ] = [];
      }
    }
    setSelectedCampaign(updatedCampaign);
    return headings;
  };

  // compare current data source headings with variables in the template
  // if a template variable doesn't have a corresponding csv heading, remove it
  const removeDeprecatedMustacheVariables = async (
    templateContent,
    dataSourceHeadings
  ) => {
    let updatedTemplateContent = removeInvalidDynamicContent(
      templateContent,
      dataSourceHeadings,
      false
    );
    if (updatedTemplateContent) {
      await saveTemplate({
        content: updatedTemplateContent,
        isNewTemplate: false,
        showMessage: false,
      }); // update the template
    }
  };
  // if campaign type is csv, personalized recipient fields are only valid if they
  // match one of the csv metadata headings
  const validatePersonalizedFields = (metadataArray) => {
    const recipients = getRecipientsFieldValues(
      selectedCampaign,
      notificationIndex
    );
    handleRecipientsInput(
      setTo,
      RECIPIENTS.TO,
      validateField(recipients[RECIPIENTS.TO], metadataArray)
    );
    handleRecipientsInput(
      setCc,
      RECIPIENTS.CC,
      validateField(recipients[RECIPIENTS.CC], metadataArray)
    );
    handleRecipientsInput(
      setBcc,
      RECIPIENTS.BCC,
      validateField(recipients[RECIPIENTS.BCC], metadataArray)
    );
  };

  // deselects the campaign, refreshes the campaign list and redirects there
  // also detects the status of the closed campaign to set the campaign status tabs accordingly
  const closeCampaign = async (statusOverride) => {
    if (statusOverride) {
      setStatusTabOverride(statusOverride);
    } else if (selectedCampaign) {
      const statuses = getCampaignStatuses(selectedCampaign);
      if (statuses.includes(CAMPAIGN_STATUSES.SENT.value)) {
        setStatusTabOverride(CAMPAIGN_STATUSES.SENT.value);
      } else if (statuses.includes(CAMPAIGN_STATUSES.SCHEDULED.value)) {
        setStatusTabOverride(CAMPAIGN_STATUSES.SCHEDULED.value);
      } else if (statuses.includes(CAMPAIGN_STATUSES.DRAFT.value)) {
        setStatusTabOverride(CAMPAIGN_STATUSES.DRAFT.value);
      } else {
        setStatusTabOverride(undefined);
      }
    } else {
      setStatusTabOverride(undefined);
    }
    toggleLoadingCampaign(true);
    await fetchCampaigns();
    originalCampaign = {};

    /*
    When navigateTo is available/not undefined, navigate to previous page(home:/) using goBack to enable browser forward option.
    Otherwise, navigate to home page using push, without enabling forward option. This happens when the user lands to the campaigns page directly in a fresh tab/window.
    */
    if (state?.from) {
      history.goBack();
    } else {
      history.replace("/");
    }
  };

  const resetWarningRequired = () => {
    if (
      to?.length ||
      cc?.length ||
      bcc?.length ||
      subject ||
      snippet ||
      from ||
      priority ||
      clickTracking ||
      viewTracking ||
      selectedTemplate
    ) {
      return true;
    }
    return false;
  };

  const resetNotificationConfigurations = () => {
    // reset campaign details page
    handleRecipientsInput(setTo, RECIPIENTS.TO, null);
    handleRecipientsInput(setCc, RECIPIENTS.CC, null);
    handleRecipientsInput(setBcc, RECIPIENTS.BCC, null);
    handleConfigurationInput(setFrom, CONFIGURATIONS.FROM, null);
    handleConfigurationInput(
      setFromDisplayName,
      CONFIGURATIONS.FROM_DISPLAY_NAME,
      null
    );
    handleConfigurationInput(setSubject, CONFIGURATIONS.SUBJECT, null);
    handleMappingUpdate(setSnippet, UI_DATA.SNIPPET_TEXT, null);
    handleConfigurationInput(setPriority, CONFIGURATIONS.PRIORITY, null);
    handleTrackingConfigInput(TRACKING_CONFIGS.CLICK, null);
    handleTrackingConfigInput(TRACKING_CONFIGS.VIEW, null);
    handleTrackingConfigInput(TRACKING_CONFIGS.ADVANCED_METRICS, null);
    handleTrackingConfigInput(TRACKING_CONFIGS.TRACK_LOCATION, null);
    handleConfigurationInput(setAttachments, CONFIGURATIONS.ATTACHMENTS, null);
  };

  // handles click of channel selection card in the edit flow
  const handleChannelSelect = (newChannel, toggleWarning) => {
    const isReset = resetWarningRequired() || campaignType || csvFile;
    if (toggleWarning && isReset) {
      toggleWarning();
    } else {
      let campaign = selectedCampaign;
      if (campaign) {
        let notification = getNotification(campaign, notificationIndex);
        if (notification) {
          resetNotificationConfigurations(); // clear out existing configs
          notification[NOTIFICATION.TEMPLATE] = null;
          setSelectedTemplate(null);
          setTemplatePreview(null);
          if (newChannel) {
            notification[NOTIFICATION.TYPE] = newChannel;
            delete notification[NOTIFICATION.TEMPLATE];
            campaign[CAMPAIGN.NOTIFICATIONS][notificationIndex] = notification;
          }
        } else if (newChannel) {
          notification = { [NOTIFICATION.TYPE]: newChannel };
          if (campaign[CAMPAIGN.NOTIFICATIONS]) {
            campaign[CAMPAIGN.NOTIFICATIONS].push(notification);
            setNotificationIndex(notificationIndex + 1);
          } else {
            campaign[CAMPAIGN.NOTIFICATIONS] = [notification];
            setNotificationIndex(0);
          }
        }
        if (!newChannel) {
          // if no channel, remove the entire notification from the array
          campaign = removeNotificationAtIndex(campaign, notificationIndex);
        }
        setChannel(newChannel);
        setSelectedCampaign(campaign);
        if (
          newChannel === NOTIFICATION_CHANNELS.teams.value
        ) {
          handleCampaignTypeSelect(
            CAMPAIGN_TYPES.personalized.value,
            false,
            true
          );
        } else {
          handleCampaignTypeSelect(CAMPAIGN_TYPES.general.value, false, true);
        }
      }
      setSteps(getStepperConfig(newChannel, campaignType));
    }
  };

  // handles click of connection type selection card in the edit flow
  const handleCampaignTypeSelect = (type, showWarning, isReset) => {
    isReset = isReset || resetWarningRequired() || csvFile;
    if (showWarning && isReset) {
      showWarning();
    } else {
      let campaign = selectedCampaign;
      campaign["general_data_source_type"] = null;
      if (campaign) {
        resetNotificationConfigurations();
        switch (type) {
          case CAMPAIGN_TYPES.personalized.value:
            campaign[CAMPAIGN.DATA_SOURCE] = {
              [DATA_SOURCE.TYPE]:
                CAMPAIGN_TYPES.personalized.dataSourceTypes[
                  CAMPAIGN_TYPES.personalized.defaultType
                ].value,
            };
            break;
          case CAMPAIGN_TYPES.general.value:
            campaign[CAMPAIGN.DATA_SOURCE] = { [DATA_SOURCE.TYPE]: "manual" };
            campaign[CAMPAIGN.METADATA] = {
              ...campaign?.[CAMPAIGN.METADATA],
              [CAMPAIGN.METADATA_HEADINGS]: [],
            };
            campaign = removeUnusedMappings(campaign, []);
            break;
          case undefined:
            campaign[CAMPAIGN.DATA_SOURCE] = {};
            campaign[CAMPAIGN.METADATA] = {
              ...campaign?.[CAMPAIGN.METADATA],
              [CAMPAIGN.METADATA_HEADINGS]: [],
            };
            campaign = removeUnusedMappings(campaign, []);
            break;
          default:
            break;
        }
        setSelectedCampaign(campaign);
        setCampaignType(type);
        removeExistingCsvIfPresent(selectedCampaign);
      }
      setSteps(getStepperConfig(channel, type));
      if (isReset) {
        saveCampaign(campaign, false);
      }
    }
  };

  const handleRecipientsInput = (
    recipientHandler,
    recipientType,
    value,
    error
  ) => {
    recipientHandler(value); // update the to, cc, or bcc useState hook
    const updatedCampaign = updateRecipientField(
      // now update the campaign object
      selectedCampaign,
      notificationIndex,
      recipientType,
      value,
      csvFile
    );
    setSelectedCampaign(updatedCampaign);
    updateFieldErrors(recipientType, error, fieldErrors, setFieldErrors);
  };

  const handleConfigurationInput = (
    configHandler,
    configName,
    value,
    error
  ) => {
    configHandler(value); // update from, subject, snippet, etc
    const updatedCampaign = updateNotificationConfig(
      // now update the campaign object
      selectedCampaign,
      notificationIndex,
      configName,
      value
    );
    setSelectedCampaign(updatedCampaign);
    updateFieldErrors(configName, error, fieldErrors, setFieldErrors);
  };

  const handleTrackingConfigInput = (trackingType, value) => {
    trackingMetricSetter[trackingType](value);

    const isTrackingTypeValid = (trackingType) =>
      trackingType === TRACKING_CONFIGS.CLICK || trackingType === TRACKING_CONFIGS.VIEW;

    const isDesignStepComplete = (steps, selectedCampaign) => {
      const designStep = steps.find(step => step.label === "Design");
      return designStep && designStep.isComplete(selectedCampaign);
    };

    if (isTrackingTypeValid(trackingType) && isDesignStepComplete(steps, selectedCampaign)) {
      // some configs changed - template needs to be saved
      toggleIsExecutable(false);
      setNeedToSaveTemplate(true);
    }

    // set trackView/trackClick
    let updatedCampaign = updateNotificationConfig(
      selectedCampaign,
      notificationIndex,
      trackingType,
      value ? String(value) : null
    );

    // set trackViewCampaignId/trackClickCampaignId
    if (Object.values(TRACKING_CONFIGS).includes(`${trackingType}CampaignId`))
      updatedCampaign = updateNotificationConfig(
        updatedCampaign,
        notificationIndex,
        `${trackingType}CampaignId`,
        value ? selectedCampaign[CAMPAIGN.UID] : null
      );

    if (
      (trackingType === TRACKING_CONFIGS.VIEW ||
        trackingType === TRACKING_CONFIGS.CLICK) &&
      !value
    ) {
      CAMPAIGN_FIELDS.TRACKING.forEach(
        ({ name, enableIfTracked, enableRef }) => {
          if (enableIfTracked && trackingType === enableRef) {
            updatedCampaign = updateNotificationConfig(
              updatedCampaign,
              notificationIndex,
              name,
              null
            );
            trackingMetricSetter[name] && trackingMetricSetter[name](false);
          }
        }
      );
    }
    setSelectedCampaign(updatedCampaign);
  };

  const handleExternalUsersOptions = (configType, value) =>{
    const isDetailStepComplete = (steps, selectedCampaign) => {
      const detailStep = steps.find(step => step.label === "Details");
      return detailStep && detailStep.isComplete(selectedCampaign,false, 0, isToggleEnabled(FEATURE.EXTERNAL_USERS_CHECK))
    };
    if (isDetailStepComplete(steps, selectedCampaign)) {
      toggleIsExecutable(false);
    }

    let updatedCampaign = updateNotificationConfig(
      selectedCampaign,
      notificationIndex,
      configType,
      value ? String(value) : null
    );

    setSelectedCampaign(updatedCampaign);
  }

  const handleCampaignNameAndCategory = async (name, categoryId) => {
    const campaign = {
      ...selectedCampaign,
      [CAMPAIGN.NAME]: name.trim(),
      [CAMPAIGN.CATEGORY_ID]: Number(categoryId),
    };
    return await saveCampaign(campaign, false);
  };

  const handleMappingUpdate = (mappingHandler, templateName, value, error) => {
    mappingHandler(value); // update the context state
    const updatedCampaign = updateMapping(
      selectedCampaign,
      notificationIndex,
      templateName,
      value,
      MAPPING.CONSTANT
    );
    setSelectedCampaign(updatedCampaign);
    updateFieldErrors(templateName, error, fieldErrors, setFieldErrors);
  };

  // determine if a campaign has been edited and needs to be saved before closing
  const checkIfEdited = () => {
    if (selectedCampaign) {
      const newCampaign = _.cloneDeep(selectedCampaign);
      if (newCampaign?.[CAMPAIGN.NOTIFICATIONS]?.length) {
        const nonEmptyNotifications = [];
        newCampaign[CAMPAIGN.NOTIFICATIONS].forEach((notification, index) => {
          if (Object.keys(notification).length) {
            const newPriority = getPriority(notification);
            // remove default priority value from consideration (unless an original priority value is set too)
            if (
              newPriority &&
              newPriority === PRIORITIES.medium.value &&
              !getPriority(originalCampaign?.[CAMPAIGN.NOTIFICATIONS]?.[index])
            ) {
              const priorityIndex = getConfigIndex(
                notification[NOTIFICATION.CONFIGURATIONS],
                CONFIGURATIONS.NAME,
                CONFIGURATIONS.PRIORITY
              );
              notification[NOTIFICATION.CONFIGURATIONS].splice(
                priorityIndex,
                1
              );
            }
            nonEmptyNotifications.push(notification);
          }
        });
        if (nonEmptyNotifications.length) {
          newCampaign[CAMPAIGN.NOTIFICATIONS] = nonEmptyNotifications;
        } else {
          delete newCampaign[CAMPAIGN.NOTIFICATIONS];
        }
      }
      delete originalCampaign?.[CAMPAIGN.METADATA];
      delete newCampaign?.[CAMPAIGN.METADATA];
      return JSON.stringify(originalCampaign) !== JSON.stringify(newCampaign);
    } else {
      return false;
    }
  };

  const duplicateCampaign = async (campaign, name, categoryId) => {
    setCampaignError(null);
    const teamId = selectedTeam[TEAM.ID];
    const userUpn = user[JWT.UPN];
    if (name && categoryId && teamId && userUpn) {
      toggleLoadingCampaign(true);
      try {
        let campaignToDuplicate = await getCampaign(campaign?.[CAMPAIGN.UID]);
        // remove uid, metadata, execution info
        delete campaignToDuplicate[CAMPAIGN.UID];
        delete campaignToDuplicate[CAMPAIGN.METADATA];
        delete campaignToDuplicate[CAMPAIGN.DATE_LAST_EXECUTED];
        delete campaignToDuplicate[CAMPAIGN.LAST_EXECUTED_BY];
        delete campaignToDuplicate[CAMPAIGN.DATE_UPDATED];
        // remove tracking configs from all notifications
        let index = 0;
        while (index < campaignToDuplicate[CAMPAIGN.NOTIFICATIONS]?.length) {
          if (
            getCampaignType(campaignToDuplicate) ===
            CAMPAIGN_TYPES.personalized.value
          ) {
            // remove the recipients fields and the auto cip mappings
            campaignToDuplicate = resetRecipients(campaignToDuplicate, index);
          }
          if (!(isUserSuperAdmin(user) || isUserAdmin(user))) {
            // remove the from field if user role is member
            campaignToDuplicate = updateNotificationConfig(
              campaignToDuplicate,
              index,
              CONFIGURATIONS.FROM,
              null
            );
          }
          campaignToDuplicate = removeUnusedMappings(campaignToDuplicate, []); // remove all csv mappings from the campaign
          campaignToDuplicate = updateNotificationConfig(
            campaignToDuplicate,
            index,
            CONFIGURATIONS.ATTACHMENTS,
            null
          );
          //reset all tracking configs
          for (const config in TRACKING_CONFIGS) {
            campaignToDuplicate = updateNotificationConfig(
              campaignToDuplicate,
              index,
              TRACKING_CONFIGS[config],
              null
            );
          }
          index = index + 1;
        }
        campaignToDuplicate = updateCronTrigger(campaignToDuplicate, null);
        // create a new campaign
        const createdId = await postCampaign({
          ...campaignToDuplicate,
          [CAMPAIGN.NAME]: name.trim(),
          [CAMPAIGN.CATEGORY_ID]: Number(categoryId),
          [CAMPAIGN.FOLDER_ID]: folder_id,
          [CAMPAIGN.IS_DRAFT]: true,
          [CAMPAIGN.TEAM_ID]: teamId,
          [CAMPAIGN.OWNER]: userUpn,
        });
        if (_isMounted.current) {
          history.push({
            pathname: `/${ROUTES.CAMPAIGN}/${createdId}`,
            state: { duplicate: true, from: "/" },
          });
          setSnackMessage(SUCCESS_MSG.DUPLICATE_CAMPAIGN);
          setSnackType(SNACK_TYPES.SUCCESS);
        }
      } catch (error) {
        let message = ERROR_MSG.POST_CAMPAIGN;
        if (error.response?.data?.code === CODES.CAMPAIGN_NAME_TAKEN) {
          message = ERROR_MSG.CAMPAIGN_NAME_TAKEN;
        }
        setCampaignError(message);
        trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: message, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.DUPLICATE_CAMPAIGN_ERROR)
        return error;
      }
    }
  };

  // handles saving the current campaign between steps
  const goToStep = async (stepIndex) => {
    let saveError;
    if (checkIfEdited()) {
      saveError = await saveCampaign(selectedCampaign, false);
    }
    if (!saveError) {
      setCurrentStep(stepIndex);
    }
  };

  const saveAsTemplate = async (templateName, templateContent) => {
    try {
      const teamId = selectedTeam[TEAM.ID];
      const userUpn = user[JWT.UPN];
      if (templateName && teamId && userUpn) {
        await postTeamTemplate(teamId, {
          [TEMPLATE.NAME]: templateName,
          [TEMPLATE.TYPE]: NOTIFICATION_CHANNELS.mail.value,
          [TEMPLATE.CONTENT]: templateContent,
          [TEMPLATE.OWNER]: userUpn,
        });
        setSnackMessage(SUCCESS_MSG.PUT_SAVED_TEMPLATE);
        setSnackType(SNACK_TYPES.SUCCESS);
      }
    } catch (error) {
      setCampaignError(ERROR_MSG.POST_SAVED_TEMPLATE);
    }
  };

  const downloadCampaignCSV = async (campaign) => {
    try {
      if (campaign) {
        toggleIsDownloading(true);
        await downloadCSV(campaign[CAMPAIGN.TEAM_ID], campaign[CAMPAIGN.UID]);
        toggleIsDownloading(false);
        setSnackMessage("Download Completed");
        setSnackType("success");
        setDownloadError(false);
      }
    } catch (err) {
      setDownloadError(true);
    }
  };

  // create one state object to pass to the Provider
  const initialState = {
    userCanEdit,
    channelAllowed,
    loadingCampaign,
    campaignError,
    setCampaignError,
    selectedCampaign,
    setSelectedCampaign,
    setNotificationIndex,
    selectCampaign,
    handleChannelSelect,
    channel,
    setChannel,
    setSubject,
    campaignType,
    setCampaignType,
    csvFile,
    setCsvFile,
    dataSourceHeadings,
    updateDataSourceHeadings,
    handleCampaignTypeSelect,
    steps,
    closeCampaign,
    createCampaign,
    notificationRecipientsFields: {
      [RECIPIENTS.TO]: {
        value: to,
        handleChange: (value, error) => {
          handleRecipientsInput(setTo, RECIPIENTS.TO, value, error);
        },
      },
      [RECIPIENTS.CC]: {
        value: cc,
        handleChange: (value, error) => {
          handleRecipientsInput(setCc, RECIPIENTS.CC, value, error);
        },
      },
      [RECIPIENTS.BCC]: {
        value: bcc,
        handleChange: (value, error) => {
          handleRecipientsInput(setBcc, RECIPIENTS.BCC, value, error);
        },
      },
    },
    notificationConfigurationFields: {
      [CONFIGURATIONS.FROM]: {
        value: from,
        handleChange: (value) => {
          handleConfigurationInput(setFrom, CONFIGURATIONS.FROM, value);
        },
      },
      [CONFIGURATIONS.FROM_DISPLAY_NAME]: {
        value: fromDisplayName,
        handleChange: (value, error) => {
          handleConfigurationInput(
            setFromDisplayName,
            CONFIGURATIONS.FROM_DISPLAY_NAME,
            value,
            error
          );
        },
        toggleDisabled: !isToggleEnabled(FEATURE.USE_FROM_DISPLAY_NAME),
      },
      [CONFIGURATIONS.SUBJECT]: {
        value: subject,
        handleChange: (value, error) => {
          handleConfigurationInput(
            setSubject,
            CONFIGURATIONS.SUBJECT,
            value,
            error
          );
        },
      },
      [CONFIGURATIONS.PRIORITY]: {
        value: priority,
        handleChange: (value) => {
          handleConfigurationInput(setPriority, CONFIGURATIONS.PRIORITY, value);
        },
      },
      [UI_DATA.SNIPPET_TEXT]: {
        value: snippet,
        handleChange: (value, error) => {
          handleMappingUpdate(setSnippet, UI_DATA.SNIPPET_TEXT, value, error);
        },
      },
      [CONFIGURATIONS.ATTACHMENTS]: {
        value: attachments,
        handleChange: (value, error) => {
          handleConfigurationInput(
            setAttachments,
            CONFIGURATIONS.ATTACHMENTS,
            value,
            error
          );
        },
      },
    },
    notificationTrackingFields: {
      [TRACKING_CONFIGS.VIEW]: {
        value: viewTracking,
        handleChange: (value) => {
          handleTrackingConfigInput(TRACKING_CONFIGS.VIEW, value);
        },
      },
      [TRACKING_CONFIGS.CLICK]: {
        value: clickTracking,
        handleChange: (value) => {
          handleTrackingConfigInput(TRACKING_CONFIGS.CLICK, value);
        },
      },
      [TRACKING_CONFIGS.ADVANCED_METRICS]: {
        value: advancedMetrics,
        handleChange: (value) => {
          handleTrackingConfigInput(TRACKING_CONFIGS.ADVANCED_METRICS, value);
        },
      },
      [TRACKING_CONFIGS.TRACK_LOCATION]: {
        value: trackLocation,
        handleChange: (value) => {
          handleTrackingConfigInput(TRACKING_CONFIGS.TRACK_LOCATION, value);
        },
      },
    },
    externalUsersOptionsFields: {
      [EXTERNAL_USER_OPTIONS_CONFIGS.INCLUDE_UNSUBSCRIBE_LINK]: {
        value: hasExternalUsers,
        options:[{label: "Yes", value: "true"}, {label: "No", value: "false"}],
        handleChange: (event) => {
          setHasExternalUsers(event.target.value)
          handleExternalUsersOptions(EXTERNAL_USER_OPTIONS_CONFIGS.INCLUDE_UNSUBSCRIBE_LINK, event.target.value)
        }}
    },
    setFromDisplayName,
    selectedTemplate,
    setSelectedTemplate,
    templatePreview,
    duplicateCampaign,
    saveCampaign,
    saveTemplate,
    sendCampaign,
    sendCampaignTest,
    scheduleCampaign,
    cancelCampaignSchedule,
    checkIfEdited,
    isExecutable,
    currentStep,
    setCurrentStep,
    goToStep,
    handleCampaignNameAndCategory,
    subject,
    clickTracking,
    viewTracking,
    fieldErrors,
    saveAsTemplate,
    calculateCampaignAttachmentSize: () => {
      return getCampaignAttachmentsSize(
        selectedCampaign?.[CAMPAIGN.TEAM_ID],
        selectedCampaign?.[CAMPAIGN.UID]
      );
    },
    downloadCampaignCSV,
    downloadError,
    setAttachments,
    isDownloading,
    savingCampaign,
    toggleSavingCampaign,
    removeExistingCsvIfPresent,
    needToSaveTemplate,
    setNeedToSaveTemplate,
    updateVariableMapping,
    handleExternalUsersOptions,
    campaignExecutions,
    loadCampaignExecutions,
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default Campaign;
