import { getAPIEndPoint, URLS } from "../consts/APIEndpoints";
import { getResponse } from "../utils/common";

//CAMPAIGN DASHBOARD
export const getESDataForECD = async (params = {}) => {
    return await getResponse(
      `${getAPIEndPoint(URLS.ENTERPRISE_ADMIN_API)}/campaigns/es`,
      "get",
      undefined,
      params
    );
}

export const getDBDataForECD = async (params = {}) => {
  return await getResponse(
      `${getAPIEndPoint(URLS.ENTERPRISE_ADMIN_API)}/campaigns/db`,
      "get",
      undefined,
      params
    );
}

export const getRealtimeDataForECD = async (params = {}) => {
    return await getResponse(
        `${getAPIEndPoint(URLS.ENTERPRISE_ADMIN_API)}/realtime`,
        "get",
        undefined,
        params
    );
}

export const getThroughPutDataForECD = async (params = {}) => {
    return await getResponse(
        `${getAPIEndPoint(URLS.ENTERPRISE_ADMIN_API)}/throughput/es`,
        "get",
        undefined,
        params
    );
}

//TEAMS DASHBOARD

// returns count of total teams and total users
export async function getTeamStats(params = {}) {
    return await getResponse(
      `${getAPIEndPoint(URLS.ENTERPRISE_ADMIN_API)}/teams/db`,
      "get",
      undefined,
      params
    );
  }
